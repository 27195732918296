import React from "react";
import mobiletransform from "../../assets/About/mobiletransform.png";
import {
  Box,
  Image,
  Icon,
  Text,
  UnorderedList,
  ListItem,
  List,
} from "@chakra-ui/react";
import Fourboxes from "../../assets/About/fourboxes.png";
import { FaFileAlt } from "react-icons/fa";

const Businesstransform = ({ data }) => {
  return (
    <Box>
      <Box>
        <Box
          mt={"36px"}
          display={"grid"}
          gridTemplateColumns={{
            xl:"repeat(2,1fr)",
            lg: "repeat(1,1fr)",
            md: "repeat(1,1fr)",
            sm:"repeat(1,1fr)",
            base: "repeat(1,1fr)",
          }}
          gap={"32px"}
        >
          {data.map((elem, index) => {
            return (
              <Box
                marginTop="7px"
                width={{ lg: "90%", md: "80%", base: "100%" }}
                marginX="auto"
                border="2px solid #d63e3e"
                borderRadius="10px"
                key={index}
              >
                <Box
                  animation={`${index * 0.3}s ease-out 0.5s 1 slideIn`}
                  _hover={{
                    animation: `${index * 0.3}s ease-out 0s 1 slideInHover`,
                  }}
                  position="relative"
                  padding={"30px"}
                  height={"200px"}
                >
                  <Text
                    color="#d63e3e"
                    height={"43px"}
                    position="absolute" // Position the icon absolutely
                    top={{lg:"-20px",md:"-16px",base:"-10px"}} // Adjust the top position as needed
                    left={"4%"} // Adjust the left position as needed
                    background={"white"}
                    fontWeight={"bold"}
                    padding={"0 8px"}
                    fontSize={{ lg: "22px", md: "20px", base: "14px" }}
                  >
                    {elem.title}
                  </Text>
                  <Text color="gray.600" ml={"20px"} fontSize={{ lg: "20px", md: "22px", base: "14px" }}>
                    <ul>
                      {elem.content.map((item, idx) => (
                        <li
                          key={idx}
                          style={{ marginTop: "12px", paddingLeft: "0" }}
                        >
                          {item}
                        </li>
                      ))}
                    </ul>
                  </Text>
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default Businesstransform;
