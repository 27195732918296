import React, { useState } from 'react';
import { Button, FormControl, FormLabel, Input, Box } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const [loginsux,setLoginsux]=useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault();
    if(localStorage.getItem("token"))
    {
        navigate("/admin")
    }
    
    try {
      // Send login request to the server
      const response = await axios.post("https://bert-labs-website.onrender.com/auth/login", {
        username,
        password
      });
      console.log(response)
      // Check if login was successful
      if (response.status === 200) {
        // Extract the token from the response data
        const token = response.data.token;
      
        // Store the token in localStorage or secure storage option
        localStorage.setItem('token', token);

        // Redirect to the appropriate page (e.g., dashboard)
        console.log("navigate")
        setLoginsux(true)
        alert("Login Successfull proceed to admin page")
        navigate("/admin");

        window.location.href = "/admin";

      } else {
        // Handle invalid credentials or other errors
        toast.error("Invalid username or password");
      }
    } catch (error) {
      // Handle network errors or other exceptions
      console.error("Error:", error);
      toast.error("An error occurred. Please try again later.");
    }

    navigate("/admin")
  };

  return (
    <Box width="100%" maxW="400px" margin="auto" mt={10} mb={200}>
      <form onSubmit={handleSubmit}>
        <FormControl id="username" mb={4}>
          <FormLabel>Username</FormLabel>
          <Input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </FormControl>
        <FormControl id="password" mb={4}>
          <FormLabel>Password</FormLabel>
          <Input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </FormControl>
        <Button type="submit" colorScheme="red">Login</Button>
        {
            loginsux ? <Button>Adminpage</Button> : null
        }
      </form>
    </Box>
  );
};

export default LoginPage;
