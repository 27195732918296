import React, { useState, useEffect } from 'react';
import { Image } from '@chakra-ui/react';
import imageone from "../../assets/Resources/Frame 4374.png"
import imagetwo from "../../assets/Resources/image 179.png"

const ImageSlider = () => {
  const [imageIndex, setImageIndex] = useState(0);
  const images = [
    imageone,
    imagetwo 
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setImageIndex(prevIndex => (prevIndex + 1) % images.length);
    }, 2000);

    return () => clearInterval(interval);
  }, [images.length]); // Ensure effect runs when images change

  return <Image src={images[imageIndex]} alt="Slider Image" width={"95%"} height={{lg:"700px",md:"450px",base:"220px"}} />;
};

export default ImageSlider;
