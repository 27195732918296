import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Button,
  IconButton,
  Text,
  Spacer,
  VStack,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import "./NavBar.css";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import db from "../../industries_db.json";
import { HashLink } from "react-router-hash-link";

const industries = db.industries;

const NavBar = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [indmodal, setIndmodal] = useState(false);
  const [activeIndustry, setActiveIndustry] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const isActiveLink = (path) => {
    return location.pathname === path;
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleIndustryClick = (industrySlug) => {
    setActiveIndustry(industrySlug);
    navigate(`/industries/${industrySlug}`);
  };

  return (
    <Box position={"sticky"} top={"0px"} zIndex={"100"}>
      <Box
        align="center"
        display={"flex"}
        backgroundColor={"white"}
        justifyContent={"space-between"}
        alignItems={"center"}
        p={{ lg: "4", md: "2", base: "1" }}
      >
        {/* Hamburger Icon and Modal */}
        <Box display={{ base: "flex", md: "flex",lg:"none" }}>
          <IconButton
            icon={isModalOpen ? <CloseIcon /> : <HamburgerIcon />}
            onClick={toggleModal}
          />
          <Box
            background="linear-gradient(111deg, #FEE 25%, #FFAFBD 60.71%)"
            position="fixed"
            top="48px"
            left="0"
            right="0"
            bottom="0"
            zIndex="999"
            display={isModalOpen ? "block" : "none"}
            backgroundColor="white"
            boxShadow="md"
            height={"380px"}
          >
            {/* Links in Modal */}
            <VStack spacing="4" marginTop="25px" onClick={toggleModal}>
              <Link to="/products" onClick={() => window.scrollTo(100, 0)}>Products</Link>
              <Link to="/industries">
                Industries
                {/* Submenu */}
              </Link>
              <Link to="/innovation">
                Innovation
              </Link>
              <Link to="/careers">Careers</Link>
              <Link to="/resources">Resources</Link>
              <Link to="/about-us">About Us</Link>
              <HashLink smooth to="/#get-in-touch">
              <Button
                alignSelf={"center"}
                p="1px"
                colorScheme="#d63e3e"
                className="button"
                width={"200px"}
              >
                Get in Touch
              </Button>
          </HashLink>{" "}
              
            </VStack>
          </Box>
        </Box>

        {/* Logo */}
        <Box>
          <Text
            objectFit="contain"
            fontSize={{ base: "26px", md: "26px", lg: "26px" }}
            className="header"
          >
            <Link to="/">Bert Labs<span style={{ verticalAlign: "top", fontSize: "0.5em" }}>TM</span></Link>
          </Text>
        </Box>

        {/* Desktop Navigation */}
        <Spacer />
        <Box display={{ base: "none", md: "none", lg: "flex" }}>
          <Text className="nav-text">
            <Link to="/products">Products</Link>
          </Text>
          <Text
            className="nav-text"
            position={"relative"}
            onMouseEnter={() => setIndmodal(true)}
            onMouseLeave={() => setIndmodal(false)}
          >
            <Link to="/industries">Industries</Link>
            {indmodal && (
              <Box
                position="absolute"
                left="0"
                backgroundColor="#fff"
                border="1px solid #ccc"
                padding="15px"
                width="180px" // Adjust the width as needed
                borderRadius="8px"
                zIndex="999"
                transition="width 0.3s ease" // Add transition for smooth expansion
                boxShadow="3px 8px 20px rgba(0, 0, 0, 0.1)"
              >
                {industries.map((industry) => (
                  <Box display={"flex"} justifyContent={"center"}>
                    <Box
                      key={industry.title}
                      as="button"
                      onClick={() => handleIndustryClick(industry.route)}
                      style={{
                        color: isActiveLink(`/industries/${industry.route}`)
                          ? "red"
                          : "inherit",
                      }}
                      _hover={{ textDecoration: "underline" }}
                    >
                      <Text>{industry.title}</Text>
                    </Box>
                  </Box>
                ))}
              </Box>
            )}
          </Text>
          <Text className="nav-text">
            <Link to="/innovation">Innovation</Link>
          </Text>
          <Text className="nav-text">
            <Link to="/careers">Careers</Link>
          </Text>
          <Text className="nav-text">
            <Link to="/resources">Resources</Link>
          </Text>
          <Text className="nav-text">
            <Link to="/about-us">About Us</Link>
          </Text>
        </Box>

        {/* Get in Touch Button */}
        <Box display={{ md: "flex" }}>
          <HashLink smooth to="/#get-in-touch">
            <Button
              colorScheme="#d63e3e"
              mr="2"
              className="button"
              marginLeft={"20px"}
            >
              Get in Touch
            </Button>
          </HashLink>{" "}
        </Box>
      </Box>
    </Box>
  );
};

export default NavBar;
