import { Box,Text,Image } from '@chakra-ui/react'
import React from 'react'
import Optimusoutput from "../../assets/About/optimusoutput.png"

const tmSuperscript= <span style={{ verticalAlign: "top", fontSize: "0.5em" }}>&#174;</span>;


const Bertoptimus = ({text,image}) => {
  return (
    <Box margin={"45px 0"} >
      <Box display={"flex"} >
        <Text fontSize={{base:"24px",lg:"32px"}} fontWeight={"bold"} display={{base:"none",lg:"block"}}>
        Bert Optimus
                <span style={{ verticalAlign: "top", fontSize: "0.5em" }}>&#174;</span>
        </Text>

      </Box>
      <Box marginTop={{lg:"25px",base:"-34px"}} marginBottom={"25px"}>
        <Text fontSize={{lg:"20px",base:"16px"}}>Bert Optimus{tmSuperscript}{" "}{text}</Text>

      </Box>
      <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
      <Image src={image}/>
      </Box>

    </Box>

  )
}

export default Bertoptimus