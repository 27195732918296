import React, { useState } from 'react';
import Leftsidebar from './Leftsidebar';
import Showjobs from './Showjobs';
import { Box } from '@chakra-ui/react';
import LoginPage from './Loginpage'; 
import { useNavigate } from 'react-router-dom';



const Adminpage = () => {
    const navigate=useNavigate()


  // If user is logged in, render the Adminpage
  return (
    <Box display={"flex"}>
      <Box width={"50%"}>
        <Leftsidebar />
      </Box>
      <Box width={"50%"}>
        <Showjobs />
      </Box>
    </Box>
  );
};

export default Adminpage;
