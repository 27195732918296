// Business Transformation Goals Card for Home Page

import React from "react";
import { Box, List, Text,ListItem } from "@chakra-ui/react";
import jsonData from "../../db.json";

const BTGCard = () => {
  const arr = jsonData.btg;

  return (
    <Box fontFamily={"Mulish"}>
      <Box display={{ base: 'block', md: 'block', lg: 'flex' }} justifyContent={{ base: 'block', md: 'block', lg: 'space-around' }} alignItems={'center'}>
        {arr.map((elem, index) => (
          <Box
            key={index}
            backgroundColor="white"
            padding={{lg:'3%',md:'7%',base:'7%'}}
            border={'2px solid rgba(242, 242, 242, 0.50)'}
            borderRadius="8px"
            boxShadow="1px 3px 20px -1px rgba(33, 33, 33, 0.15)"
            margin={'10px 0px'}
            width={{lg:'31%',md:'100%',base:'100%'}}
            height={{lg:'350px',md:'250px',base:'300px'}}
            _hover={{
                boxShadow: "0 0 50px rgba(7, 0, 0, 0.15)",
                transition: "box-shadow 0.3s ease-in-out", 
              }}
          >
            <Text fontSize={{lg:"23px",md:"25px", base:"18px"}} color="#d63e3e" fontFamily="Mulish" fontWeight={'500'} marginBottom="8px">{elem.title}</Text>
            <List style={{padding:'16px'}} styleType={'disc'}>
              {elem.values.map((value, subIndex) => (
                <ListItem key={subIndex} fontFamily="Mulish" color={'#707070'}>{value}</ListItem>
              ))}
            </List>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default BTGCard;
