import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Box, Heading, Image, Text } from '@chakra-ui/react';

const CustomSlider = ({ data }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <Slider {...settings}>
      {data.map((item, index) => (
        <Box key={index}  p="20px" borderRadius="27px" textAlign="center" gap={"10px"} border={"2px solid red"}>
          <Image src={item.image} alt={item.title} borderRadius="17px" />
          <Heading as="h1" size="md" mt={4}>
            {item.title}
          </Heading>
          <Text mt={2}>{item.description}</Text>
        </Box>
      ))}
    </Slider>
  );
};

export default CustomSlider;
