import React, { useRef, useState } from "react";
import {
  Box,
  ChakraProvider,
  Text,
  Image,
  Button,
  Progress,
} from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import "./SCPCard.css";

import minniecomm from "../../assets/Products/maximus/bert-minniecomm.png";
import minnie from "../../assets/Products/maximus/bert-minnie.png";
import titan from "../../assets/Products/maximus/bert-titan.png";
import aksh from "../../assets/Products/maximus/bert-aksh.png";
import milo from "../../assets/Products/maximus/bert-milo.png";
import minion from "../../assets/Products/maximus/bert-minion.png";
import qriousNode from "../../assets/Products/maximus/bert-branch.png";


const deviceData=[
    {
      title: "Bert Minniecomm",
      image: minniecomm,
      desc:"Bert MinnieComm is a smart controller and data logging device which allows you to monitor and send control commands to the PLCs of various unit stations in smart buildings and industries in real-time."
    },
    {
      title: "Bert Minnie",
      image: minnie,
      desc:"Bert Minnie captures operational parameters of utility equipment and factory process line equipment through direct IO integration."
    },
    {
      title: "Bert Titan",
      image: titan,
      desc:"Bert Titan connects to the Wi-Fi network to send and receive data from all leaf nodes and Branch Nodes of various devices in offices and industries in real-time, and then send it to Bert Nova™."
    },
    {
      title: "Bert Aksh",
      image: aksh,
      desc: "Bert Aksh is a smart IoT device which is used to perform vision based quality assurance check and sends real-time meta-data to the cloud."
    },
    {
      title: "Bert Milo",
      image: milo,
      desc:"Bert Milo is a smart wireless temperature and humidity monitoring device which allows you to monitor and control the temperature and humidity of various devices in offices and industries in real-time."
    },
    {
      title: "Bert Minion",
      image: minion,
      desc:"Bert Minion is a smart energy monitoring device which allows you to monitor and control the energy consumption of various devices in offices and industries in real-time."
    },
    {
      title: "Bert Qrious® Node",
      image: qriousNode,
      desc:"Bert Qrious® Node is a smart IoT device which allows you to capture and relay real-time messages of leaf nodes to Bert Titan."
    },
  ];

  const ProgressBar = ({ currentSlide, slideCount,settings }) => {
    const slidesToShow = settings.responsive.find((breakpoint) => window.innerWidth <= breakpoint.breakpoint)?.settings.slidesToShow-2 || settings.slidesToShow;
    const progress = ((currentSlide + slidesToShow ) / slideCount) * 100;
    console.log(currentSlide,slidesToShow)
    return <Progress value={progress} colorScheme="red" height={'4px'} borderRadius={'2px'}/>;
  };


const Devices = () => {
    const slider = useRef(null);
    const [currentSlide, setCurrentSlide] = useState(0);
  
    var settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,
      initialSlide: 0,
      prevArrow: null, // Remove default previous arrow
      nextArrow: null, // Remove default next arrow
      beforeChange: (oldIndex, newIndex) => {
          setCurrentSlide(newIndex);
        },
      responsive: [
          {
              breakpoint: 1224,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
              },
            },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
  
    const handlePrevClick = () => {
      slider.current.slickPrev();
    };
  
    const handleNextClick = () => {
      slider.current.slickNext();
    };
  
    return (
      <ChakraProvider>
        <Box position={"relative"} padding={{lg:'3%',md:'7%',base:'7%'}}>
          {/* <Text fontSize={"24px"}>Solving Complex Problems for</Text> */}
  
          <Box position={"absolute"} left={"-2%"} top={"45%"}>
            <Button onClick={handlePrevClick} variant="unstyled">
              <ChevronLeftIcon boxSize={8} color="black" />
            </Button>
          </Box>
  
          <Box>
            <Slider {...settings} ref={slider} className="slick-slider">
              {deviceData.map((item, index) => (
                <Box key={index} p={{base:"2",lg:"4"}}>
                  <Box
                    boxShadow="md"
                    borderRadius="md"
                    overflow="hidden"
                    borderWidth="1px"
                    borderColor="gray.200"
                  >
                    <Image src={item.image} w={"100%"} h="auto" bg="gray.100" />
                    <Box p="4" height={"320px"}>
                      <Text fontWeight="semibold" mb="2" fontSize={{base:"24px",lg:"32px"}}>
                        {item.title}
                      </Text>
                      <Text color="gray.600" fontSize={{base:"16px",lg:"16px"}}>{item.desc}</Text>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Slider>
            <ProgressBar currentSlide={currentSlide} slideCount={deviceData.length}  settings={settings}/>
          </Box>
          <Box position={"absolute"} right={"-2%"} top={"45%"}>
            <Button onClick={handleNextClick} variant="unstyled">
              <ChevronRightIcon boxSize={8} color="black" />
            </Button>
          </Box>
        </Box>
      </ChakraProvider>
    );
};

export default Devices