import React from 'react'
import axios from "axios"
import { useEffect,useState } from 'react';
import {
    Box,
    Heading,
    Text,
    List,
    Divider,
    Container,
    Select,
  } from '@chakra-ui/react';
import Leftsidebar from './Leftsidebar'

const Bro=()=>{
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState('');
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get('https://bert-labs-website.onrender.com/wp');
          setData(response.data);
          setFilteredData(response.data); // Initialize filtered data with all data
          
          // Extract unique company names for filtering
          const uniqueCompanies = [...new Set(response.data.map(item => item.organization))];
          setCompanies(uniqueCompanies);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
  
      fetchData();
    }, []);
  
    useEffect(() => {
      // Filter data based on selected company
      if (selectedCompany === '') {
        setFilteredData(data);
      } else {
        const filtered = data.filter(item => item.organization === selectedCompany);
        setFilteredData(filtered);
      }
    }, [selectedCompany, data]);
  
    const handleCompanyChange = (event) => {
      setSelectedCompany(event.target.value);
    };
  
    return (
      <Container maxW="container.lg" mt={10}>
        <Heading as="h1" size="xl" mb={6}>
          Data List
        </Heading>
        <Select
          value={selectedCompany}
          onChange={handleCompanyChange}
          placeholder="Filter by Company"
          mb={4}
        >
          <option value="">All Companies</option>
          {companies.map((company) => (
            <option key={company} value={company}>
              {company}
            </option>
          ))}
        </Select>
        <List spacing={3}>
          {filteredData.map((item) => (
            <Box key={item._id} p={4} borderWidth="1px" borderRadius="lg">
              <Text>
                <strong>Name:</strong> {item.firstName} {item.lastName}
              </Text>
              <Text>
                <strong>Organization:</strong> {item.organization}
              </Text>
              <Text>
                <strong>Designation:</strong> {item.designation}
              </Text>
              <Text>
                <strong>Email:</strong> {item.email}
              </Text>
              <Text>
                <strong>Contact No:</strong> {item.contactNo}
              </Text>
              <Text>
                <strong>LinkedIn:</strong> {item.linkedinProfile}
              </Text>
              <Text>
                <strong>Message:</strong> {item.message}
              </Text>
              <Divider mt={3} mb={3} />
            </Box>
          ))}
        </List>
      </Container>
  );
}
const Brochure = () => {
  return (
    <Box display={"flex"}>
    <Box width={"50%"}>
      <Leftsidebar />
    </Box>
    <Box width={"50%"} ml={"-10%"} mb={"10px"}>
      <Bro/>
    </Box>
  </Box>
  )
}

export default Brochure