import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { VStack, Text, Box, Button, Select } from '@chakra-ui/react';
import Leftsidebar from './Leftsidebar';

function ContactList() {
  const [contacts, setContacts] = useState([]);
  const [filteredContacts, setFilteredContacts] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [categories, setCategories] = useState(new Set());

  useEffect(() => {
    // Fetch data from the endpoint
    axios.get('https://bert-labs-website.onrender.com/contact')
      .then(response => {
        // Set the fetched contacts data to state
        setContacts(response.data);
        setFilteredContacts(response.data);
        // Extract unique categories from the fetched data
        const uniqueCategories = new Set(response.data.map(contact => contact.category));
        setCategories(uniqueCategories);
      })
      .catch(error => {
        console.error('Error fetching contact data:', error);
      });
  }, []); // Empty dependency array ensures useEffect runs only once on component mount

  const handleDeleteContact = (id) => {
    // Send delete request to the endpoint to delete the contact
    axios.delete(`https://bert-labs-website.onrender.com/contact/${id}`)
      .then(response => {
        // Filter out the deleted contact from the state
        setContacts(contacts.filter(contact => contact._id !== id));
        setFilteredContacts(filteredContacts.filter(contact => contact._id !== id));
        console.log('Contact deleted successfully:', response.data);
      })
      .catch(error => {
        console.error('Error deleting contact:', error);
      });
  };

  const handleCategoryChange = (e) => {
    const selectedCategory = e.target.value;
    setSelectedCategory(selectedCategory);
    if (selectedCategory) {
      const filteredList = contacts.filter(contact => contact.category === selectedCategory);
      setFilteredContacts(filteredList);
    } else {
      setFilteredContacts(contacts);
    }
  };

  const Card = () => {
    return (
      <VStack spacing={4} alignItems="flex-start" p={4}>
        <Text fontSize="2xl" fontWeight="bold" mb={4}>Contact List</Text>
        <Select value={selectedCategory} onChange={handleCategoryChange} w="50%">
          <option value="">Filter by category</option>
          {/* Dynamically generate options based on unique categories */}
          {[...categories].map(category => (
            <option key={category} value={category}>{category}</option>
          ))}
        </Select>
        {filteredContacts.map(contact => (
          <Box key={contact._id} border="1px" p={4} w="100%">
            <Text fontWeight="bold">Name: {contact.firstName} {contact.lastName}</Text>
            <Text><Text as="span" fontWeight="bold">Email:</Text> {contact.email}</Text>
            <Text><Text as="span" fontWeight="bold">Company:</Text> {contact.companyName}</Text>
            <Text><Text as="span" fontWeight="bold">Phone Number:</Text> {contact.phoneNumber}</Text>
            <Text><Text as="span" fontWeight="bold">Message:</Text> {contact.message || 'No message'}</Text>
            <Text><Text as="span" fontWeight="bold">Category:</Text> {contact.category}</Text>
            <Button colorScheme="red" onClick={() => handleDeleteContact(contact._id)} mt={"21px"}>Delete</Button>
          </Box>
        ))}
      </VStack>
    )
  }

  return (
    <Box display={"flex"}>
      <Box width={"50%"}>
        <Leftsidebar />
      </Box>
      <Box width={"50%"} ml={"-10%"} mb={"10px"}>
        <Card />
      </Box>
    </Box>
  );
}

export default ContactList;
