import React, { useRef, useState } from "react";
import {
  Box,
  ChakraProvider,
  Text,
  Image,
  Button,
  Progress,
} from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./SCPCard.css";

import automobile from "../../assets/industries/Automobile.png";
import cement from "../../assets/industries/Cement.png";
import commercialBuilding from "../../assets/industries/Commercial Building.png";
import fmcg from "../../assets/industries/FMCG.png";
import material from "../../assets/industries/Material.png";
import pharmaceutical from "../../assets/industries/Pharmaceutical.png";
import pulpPaper from "../../assets/industries/Pulp&Paper.png";
import chemicals from "../../assets/industries/SpecialityChemicals.png";
import {Link} from "react-router-dom"

const scpData = [
  {
    title: "Airport",
    route:"airport",
    image: commercialBuilding,
    desc: "HVAC-Utility system Operational Digital Twin and Reinforcement Learning Agent for PMV powered Thermal Comfort, power & fuel consumption reduction, and improving asset integrity",
  },
  {
    title: "Automobile",
    route:"automobile",
    image: automobile,
    desc: "Paint shop PTED, Oven, Paint Booth (manual and robotic) Operational Digital Twins and Reinforcement Learning Agents for fuel and power consumption reduction, for improving JPH, reducing paint and other consumable cost, and reducing cost towards maintenance of the unit operations.",
  },
  {
    title: "Cement",
    route:"cement",
    image: cement,
    desc: "Pyro process, Preheaters, Cyclones, Kiln, Pre-coolers Operational Digital Twins and Reinforcement Agents for reduction in coal consumption and power consumption, and higher Clinker quality, and higher kiln reliability",
  },
  {
    title: "FMCG",
    route:"fmcg",
    image: fmcg,
    desc: "Beverage bottling plant, Saponification, and Toothpaste line Operational Digital Twins and Reinforcement Learning Agents ensure that the process is maintained as per the required specifications and help bring in energy efficiency and production efficiency improvement.",
  },
  {
    title: "Hotel",
    route:"hotel",
    image: commercialBuilding,
    desc: "HVAC-Utility system Operational Digital Twin and Reinforcement Learning Agent for PMV powered Thermal Comfort, power & fuel consumption reduction, and improving asset integrity",
  },
  {
    title: "Material",
    route:"material",
    image: material,
    desc: "Shuttle Kiln and Tunnel Kiln Operational Digital Twins and Reinforcement Learning Agents to maintain the temperature profile of the Kiln as per material requirement and bring in improved efficiency in consumption of power and fuel",
  },
  {
    title: "Office Building",
    route:"office-building",
    image: commercialBuilding,
    desc: "HVAC-Utility system Operational Digital Twin and Reinforcement Learning Agent for PMV powered Thermal Comfort, power & fuel consumption reduction, and improving asset integrity",
  },
  {
    title: "Pharmaceuticals",
    route:"pharmaceutical",
    image: pharmaceutical,
    desc: "HVAC-Utility System and Production Equipment Operational Digital Twins and Reinforcement Learning Agents ensure that the required constraints as per the cGMP & regulatory requirements are met and reduction in power and fuel consumption, yield improvement, quality improvement, asset integrity.",
  },
  {
    title: "Pulp & Paper",
    route:"pulp-and-paper",
    image: pulpPaper,
    desc: "Chemical Recovery Section (evaporator, digester, green boiler) and Paper Machine Operational Digital Twins and Reinforcement Learning Agents bring down the steam consumption, improve steam economy, reduce coal consumption in the boiler, and reduce power consumption.",
  },
  {
    title: "Specialty Chemicals",
    route:"speciality-chemical",
    image: chemicals,
    desc: "Soda-ash Ammonia Recovery, Carbonation, Chlor-Alkali Electrolysis, VSF TRP Operational Digital Twins and Reinforcement Learning Agents bring yield improvement and reduction in power consumption, while maintaining the process constraints and improving the life of machines/components/ sub-components.",
  },
];


const ProgressBar = ({ currentSlide, slideCount }) => {
    const progress = ((currentSlide + 1) / slideCount) * 100;
    return <Progress value={progress} colorScheme="red" height={'4px'} borderRadius={'2px'}/>;
  };

const SCPCard = () => {
  const slider = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    prevArrow: null, // Remove default previous arrow
    nextArrow: null, // Remove default next arrow
    beforeChange: (oldIndex, newIndex) => {
        setCurrentSlide(newIndex);
      },
    responsive: [
        {
            breakpoint: 1224,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
            },
          },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handlePrevClick = () => {
    slider.current.slickPrev();
  };

  const handleNextClick = () => {
    slider.current.slickNext();
  };

  return (
    <ChakraProvider>
      <Box position={"relative"} padding={{lg:'3%',md:'7%',base:'7%'}} fontFamily="Mulish">
        <Text  fontSize={{ lg: "36px", md: "38px", base: "18px" }}
              fontWeight={{ lg: "900", md: "800", base: "700" }}>Solving Complex Problems for</Text>

        <Box position={"absolute"} left={"-2%"} top={"45%"}>
          <Button onClick={handlePrevClick} variant="unstyled">
            <ChevronLeftIcon boxSize={8} color="black" />
          </Button>
        </Box>

        <Box>
          <Slider {...settings} ref={slider} className="slick-slider">
            {scpData.map((item, index) => (
              <Box key={index} p="4">
                <Box
                  boxShadow="md"
                  borderRadius="md"
                  overflow="hidden"
                  borderWidth="1px"
                  borderColor="gray.200"
                  height="550px"
                  position="relative"
                >
                  <Image src={item.image} h="230px" bg="gray.100" />
                  <Box p="4">
                    <Text fontWeight="semibold" mb="2">
                      {item.title}
                    </Text>
                    <Text color="gray.600">{item.desc}</Text>
                  </Box>
                  <Box position={"absolute"} bottom={"3px"} right={"7px"} color={"#0d6efd"} _hover={{textDecoration:"underline"}}><Link to={`/industries/${item.route}`}>view more...</Link></Box>
                </Box>
              </Box>
            ))}
          </Slider>
          <ProgressBar currentSlide={currentSlide} slideCount={scpData.length}/>
        </Box>
        <Box position={"absolute"} right={"-2%"} top={"45%"}>
          <Button onClick={handleNextClick} variant="unstyled">
            <ChevronRightIcon boxSize={8} color="black" />
          </Button>
        </Box>
      </Box>
    </ChakraProvider>
  );
};

export default SCPCard;
