import React, { useState } from "react";
import { Link } from "react-router-dom";
import {HashLink} from "react-router-hash-link"
import {
  Box,
  Flex,
  Text,
  Icon,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Image,
} from "@chakra-ui/react";
import { BiMap } from "react-icons/bi";
import { AiOutlineMail } from "react-icons/ai";

import "./Footer.css";

import { FaYoutube } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { SiWellfound } from "react-icons/si";
import { FaInstagram } from "react-icons/fa";
import { MdCopyright } from "react-icons/md";



const Footer = () => {
  const [openAccordionIndex, setOpenAccordionIndex] = useState(null);

  const handleAccordionClick = (index) => {
    setOpenAccordionIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <Box fontFamily={"Mulish"}>
      <Box
        background="linear-gradient(111deg, #FEE 25%, #FFAFBD 60.71%)"
        position="static"
        bottom="0px"
        width="100%"
        padding={{lg:"50px",md:"40px",base:"20px"}}
      >
        <Box
          width={{ base: "100%", md: "65%" }}
          display={{ base: "flex", md: "flex", lg: "none" }}
          justifyContent="space-around"
          padding="20px"
        >
          <Accordion allowMultiple={false} width="100%">
            <AccordionItem border="none">
              <AccordionButton onClick={() => handleAccordionClick(0)}>
                <Box flex="1" textAlign="left">
                  <h1 className="section-heading">Products</h1>
                </Box>
                <AccordionIcon
                  transform={openAccordionIndex === 0 ? "rotate(180deg)" : ""}
                />
              </AccordionButton>
              <AccordionPanel
                pb={4}
                display={openAccordionIndex === 0 ? "block" : "none"}
              >
                <HashLink smooth to="/products/#bert-maximus"><Text>Bert Maximus<span style={{ verticalAlign: "top", fontSize: "0.5em" }}>&#174;</span></Text></HashLink>
                <HashLink smooth to="/products/#bert-qrious"><Text>Bert Qrious<span style={{ verticalAlign: "top", fontSize: "0.5em" }}>&#174;</span></Text></HashLink>
                <HashLink smooth to="/products/#bert-nova"><Text>Bert Nova<span style={{ verticalAlign: "top", fontSize: "0.5em" }}>TM</span></Text></HashLink>
                <HashLink smooth to="/products/#bert-geminus"><Text>Bert Geminus<span style={{ verticalAlign: "top", fontSize: "0.5em" }}>TM</span></Text></HashLink>
                <HashLink smooth to="/products/#bert-optimus"><Text>Bert Optimus<span style={{ verticalAlign: "top", fontSize: "0.5em" }}>&#174;</span></Text></HashLink>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem border="none">
              <AccordionButton onClick={() => handleAccordionClick(1)}>
                <Box flex="1" textAlign="left">
                  <h1 className="section-heading">Industries</h1>
                </Box>
                <AccordionIcon
                  transform={openAccordionIndex === 1 ? "rotate(180deg)" : ""}
                />
              </AccordionButton>
              <AccordionPanel
                pb={4}
                display={openAccordionIndex === 1 ? "block" : "none"}
              >
                <Link to="/industries/airport">
                  <Text>Airport</Text>
                </Link>
                <Link to="/industries/automobile">
                  <Text>Automobile</Text>
                </Link>
                <Link to="/industries/cement">
                  <Text>Cement</Text>
                </Link>
                <Link to="/industries/speciality-chemical">
                  <Text>Chemicals</Text>
                </Link>
                <Link to="/industries/fmcg">
                  <Text>FMCG</Text>
                </Link>
                <Link to="/industries/hotel">
                  <Text>Hotel</Text>
                </Link>
                <Link to="/industries/material">
                  <Text>Materials</Text>
                </Link>
                <Link to="/industries/office-building">
                  <Text>Office Building</Text>
                </Link>
                <Link to="/industries/pharmaceutical">
                  <Text>Pharmaceutical</Text>
                </Link>
                <Link to="/industries/pulp-and-paper">
                  <Text>Pulp & Paper</Text>
                </Link>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem border="none">
              <AccordionButton onClick={() => handleAccordionClick(2)}>
                <Box flex="1" textAlign="left">
                  <h1 className="section-heading">Company</h1>
                </Box>
                <AccordionIcon
                  transform={openAccordionIndex === 2 ? "rotate(180deg)" : ""}
                />
              </AccordionButton>
              <AccordionPanel
                pb={4}
                display={openAccordionIndex === 2 ? "block" : "none"}
              >
                <Link to="/about-us">
                  <Text>About Us</Text>
                </Link>
                <Link to="/careers">
                  <Text>Careers</Text>
                </Link>
                <Link to="/resources">
                  <Text>Resources</Text>
                </Link>
                <Link to="/admin">
                  <Text>Admin</Text>
                </Link>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Box>
        {/* Rest of your content */}
        <Flex>
          {/* 40% width div */}

          <Box
            width={{ base: "100%", lg: "35%" }}
            padding={"20px"}
            display={"flex"}
            flexDirection={"column"}
          >
            {/* Content of the 40% width div */}
            <Box className="header" marginLeft={"10px"}>
              Bert Labs<span style={{ verticalAlign: "top", fontSize: "0.5em" }}>TM</span>
            </Box>
            <Box display={"flex"} margin={"10px"}>
              <a
                href="https://www.google.com/maps/dir//3rd+Floor,+613%2FA,+15th+Cross,+Dr+Puneeth+Rajkumar+Rd,+J.+P.+Nagar,+33rdMain,+Bengaluru,+Karnataka+560078/@12.9065282,77.4981019,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3bae1530a7c9f3cd:0x4ba8f28a6097f435!2m2!1d77.5805038!2d12.9065411?entry=ttu"
                target="_blank"
                rel="noopener noreferrer"
              ><Flex>
                <Icon as={BiMap} boxSize={8} marginRight={"5px"} />
                <Text>
                  Bert Labs<span style={{ verticalAlign: "top", fontSize: "0.5em" }}>TM</span> Private Limited 3rd Floor, 613/A, 15th Cross, 33rd
                  Main, 100 Feet Ring Road, J. P. Nagar, 1st Phase, Bengaluru,
                  Karnataka 560078 India
                </Text></Flex>
              </a>
            </Box>
            <Box display={"flex"} m={"0 0 10px 10px"}>
              <Icon as={AiOutlineMail} boxSize={7} marginRight={"5px"} />
              <Text>
                {/* bert@bertlabs.com */}
                <a href="mailto:bert@bertlabs.com">bert@bertlabs.com</a>
              </Text>
            </Box>
          </Box>
          {/* 60% width div */}
          <div className="vert-rule"></div>
          <Box
            width="65%"
            display={{ base: "none", md: "none", lg: "flex" }}
            justifyContent={"space-around"}
            padding={"20px"}
          >
            {/* Nested divs within the 60% width div */}
            <Box>
              <h1 className="section-heading head-res">Products</h1>
              <Box>
                <HashLink smooth to="/products/#bert-maximus"><Text>Bert Maximus<span style={{ verticalAlign: "top", fontSize: "0.5em" }}>&#174;</span></Text></HashLink>
                <HashLink smooth to="/products/#bert-qrious"><Text>Bert Qrious<span style={{ verticalAlign: "top", fontSize: "0.5em" }}>&#174;</span></Text></HashLink>
                <HashLink smooth to="/products/#bert-nova"><Text>Bert Nova<span style={{ verticalAlign: "top", fontSize: "0.5em" }}>TM</span></Text></HashLink>
                <HashLink smooth to="/products/#bert-geminus"><Text>Bert Geminus<span style={{ verticalAlign: "top", fontSize: "0.5em" }}>TM</span></Text></HashLink>
                <HashLink smooth to="/products/#bert-optimus"><Text>Bert Optimus<span style={{ verticalAlign: "top", fontSize: "0.5em" }}>&#174;</span></Text></HashLink>
              </Box>
            </Box>
            <Box>
              <h1 className="section-heading head-res">Industries</h1>
              <Box>
                <Link to="/industries/airport">
                  <Text>Airport</Text>
                </Link>
                <Link to="/industries/automobile">
                  <Text>Automobile</Text>
                </Link>
                <Link to="/industries/cement">
                  <Text>Cement</Text>
                </Link>
                <Link to="/industries/speciality-chemical">
                  <Text>Chemicals</Text>
                </Link>
                <Link to="/industries/fmcg">
                  <Text>FMCG</Text>
                </Link>
                <Link to="/industries/hotel">
                  <Text>Hotel</Text>
                </Link>
                <Link to="/industries/material">
                  <Text>Materials</Text>
                </Link>
                <Link to="/industries/office-building">
                  <Text>Office Building</Text>
                </Link>
                <Link to="/industries/pharmaceutical">
                  <Text>Pharmaceutical</Text>
                </Link>
                <Link to="/industries/pulp-and-paper">
                  <Text>Pulp & Paper</Text>
                </Link>
              </Box>
            </Box>
            <Box>
              <h1 className="section-heading head-res">Company</h1>
              <Box>
                <Link to="/about-us">
                  <Text>About Us</Text>
                </Link>
                <Link to="/careers">
                  <Text>Careers</Text>
                </Link>
                <Link to="/resources">
                  <Text>Resources</Text>
                </Link>
                <Link to="/admin">
                  <Text>Admin</Text>
                </Link>
              </Box>
            </Box>
          </Box>
        </Flex>
        <Box
          width="100%"
          padding={"5px 50px"}
          display={"flex"}
          justifyContent={"space-between"}
          flexDirection={{ base: "column", md: "row" }}
        >
          <Box
            display={"flex"}
            marginBottom={{ base: "10px", md: 0 }}
            justifyContent={{ base: "center", md: "flex-end" }}
          >
            <Text fontSize={"12px"} display={"flex"} alignItems={"center"}>
              Copyright{"     "} <MdCopyright/> {"     "}| Security | Terms of Service
            </Text>
          </Box>

          <Box
            display={"flex"}
            justifyContent={{ base: "center", md: "flex-end" }}
            alignItems={"center"}
          >
            <Box width={"200px"} display={"flex"} justifyContent={"space-around"} alignItems={"center"}><Link
              to="https://wellfound.com/company/bert-labs/jobs"
            >
              <SiWellfound size={20}/>
            </Link>

            <Link
              to="https://www.instagram.com/bertlabs/"
            >
              <FaInstagram size={20}/>
            </Link>

            <Link
              to="https://www.instahyre.com/jobs-at-bert-labs/"
            >
              <Image
                src={"https://th.bing.com/th?id=ODLS.44448249-0802-4627-a7d9-8eec1fd82691&w=32&h=32&qlt=93&pcl=fffffa&o=6&pid=1.2"}
                height="17.5px"
                width="17.5px"
                margin={0}
              />
            </Link>

            <Link
              to="https://www.linkedin.com/company/bert-labs/"
            >
              <FaLinkedin size={20}/>
            </Link>

            <Link
              to="https://www.youtube.com/@bertlabs"
            >
              <FaYoutube size={20}/>
            </Link></Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
