import React from "react";
import { Box, Image, Text } from "@chakra-ui/react";

const tmSuperscript= <span style={{ verticalAlign: "top", fontSize: "0.5em" }}>TM</span>;


const Bertgeminos = ({ text, image }) => {
  return (
    <Box margin={"45px 0"}>
      <Box display={"flex"} >
        <Text fontSize={{base:"24px",lg:"32px"}} fontWeight={"bold"} display={{base:"none",lg:"block"}}>
        Bert Geminus<span style={{ verticalAlign: "top", fontSize: "0.5em" }}>TM</span>
        </Text>

      </Box>
      <Box marginTop={{lg:"25px",base:"-34px"}} marginBottom={"25px"} >
        <Text fontSize={{lg:"20px",base:"16px"}}>Bert Geminus{tmSuperscript}{" "}{text}</Text>

      </Box>
      <Box display={"flex"} justifyContent={"center"}><Image src={image}  width={{lg:"70%",base:"90%"}}/></Box>
      
    </Box>
  );
};

export default Bertgeminos;
