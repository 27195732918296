import React from 'react';
import './App.css';
import NavBar from './Components/NavBar/NavBar';
import { Router } from 'react-router-dom';
import AllRoutes from './Routes/Allroutes';
import { ChakraProvider } from '@chakra-ui/react';

function App() {
  return (
    <ChakraProvider className="App">
      <AllRoutes/>
    </ChakraProvider>
  );
}

export default App;
