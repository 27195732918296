// AllRoutes.js
import React from "react";
import { Routes, Route } from "react-router-dom";
import NavBar from "../Components/NavBar/NavBar";
import About from "../Pages/About";
import Home from "../Pages/Home";
import Contacts from "../Pages/Admin/Tocontact";
import Products from "../Pages/Products";
import Industries from "../Pages/Industries";
import Careers from "../Pages/Careers";
import Resources from "../Pages/Resources";
import Footer from "../Components/Footer/Footer";
import Adminpage from "../Pages/Admin/Adminpage";
import db from "../industries_db.json";
import Showjobs from "../Pages/Admin/Showjobs";
import Jobinput from "../Pages/Admin/Jobinput";
import Showresources from "../Pages/Admin/Showresources";
import ResourceForm from "../Pages/Admin/Resourceinput";
import Teams from "../Pages/Teams";
import Demo from "../Pages/Demo";
import Apply from "../Pages/Apply";
import Applicants from "../Pages/Admin/Applicants";
import Loginpage from "../Pages/Admin/Loginpage";
import PrivateRoutes from "../Pages/Admin/PrivateRoutes";
import Tocontact from "../Pages/Admin/Tocontact";
import Innovation from "../Pages/Innovation";
import Brochure from "../Pages/Admin/Brochure";
import Singleblog from "../Pages/Singleblog";

const AllRoutes = () => {
  return (
    <>
      <NavBar />
      {/* <p style={{textAlign:"center", padding:"20px",border:"3px solid aqua"}}>Arpit is here with us</p> */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/products" element={<Products />} />
        <Route path="/industries" element={<Industries industry={db.industries[0]} />} />
        {db.industries.map((industry) => (
          <Route
            key={industry.title}
            path={`/industries/${industry.route}`}
            element={<Industries industry={industry} />}
          />
        ))}
        <Route path="/innovation" element={<Innovation />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/resources" element={<Resources />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/contacts" element={<Contacts />} />
        <Route path="/teams" element={<Teams />} />
        <Route path="/demo/:id" element={<Demo />} />
        <Route path="/blogs/:id" element={<Singleblog />} />
        <Route element={<PrivateRoutes/>}>
        <Route path="/admin" element={<Adminpage />} />
        <Route path="/admin/jobs" element={<Adminpage />} />
        <Route path="/admin/createjobs" element={<Jobinput />} />
        <Route path="/admin/resources" element={<Showresources />} />
        <Route path="/admin/createresources" element={<ResourceForm />} />
        <Route path="/admin/contacts" element={<Tocontact />} />
        </Route>
        <Route path="/careers/apply/:id" element={<Apply />} />
        <Route path="/admin/applicants" element={<Applicants />} />
        <Route path="/admin/brochure" element={<Brochure />} />
        <Route path="/login" element={<Loginpage />} />
      </Routes>
      <Footer />
    </>
  );
};

export default AllRoutes;
