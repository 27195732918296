import React from 'react';
import { Box, Flex, Image } from '@chakra-ui/react';
import Bertnovaimage from "../../assets/About/bertnovaimage.svg"; 

const Bertnova = () => {
  return (
    <Box width="100%" margin="auto"  mt={"25px"} fontFamily={"Mulish"}>
        <Flex fontSize={{base:"24px",lg:"32px"}} fontWeight={"bold"} height="100%" fontFamily={"Mulish"} display={{lg:"block",base:"none"}}>
      
      Bert Nova
                <span style={{ verticalAlign: "top", fontSize: "0.5em" }}>TM</span>
    </Flex>
       
      <Flex justifyContent="center" alignItems="center" height="100%">
      
        <Image src={Bertnovaimage} />
      </Flex>
    </Box>
  );
};

export default Bertnova;
