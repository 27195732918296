import React, { useEffect, useState } from "react";
import frontImage from "../assets/Careerpage/Vector.svg";
import hiringimage from "../assets/Careerpage/Property 1=Default.svg";
import { Box, Image, Text, Select, Icon } from "@chakra-ui/react";
import bertteam from "../assets/Careerpage/bert-team.png";
import tick from "../assets/Careerpage/charm_circle-tick.png";
import { useNavigate } from "react-router-dom";
import {
  Accordion,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  AccordionItem,
  Button,
} from "@chakra-ui/react";
import mail from "../assets/Careerpage/look.svg";
import vectornew from "../assets/Careerpage/Vector 293.png";
import axios from "axios";
import { FaMapMarkerAlt } from "react-icons/fa";
import { FaFileAlt } from "react-icons/fa";
import { FaEnvelope } from "react-icons/fa";
import { IoMail } from "react-icons/io5";

const values = [
  {
    title: "Inclusive Workplace",
    description:
      "Bert Labs™ is committed to equality and pay parity, rejecting gender-specific roles and discrimination in compensation.",
  },
  {
    title: "Exponential Career Growth",
    description:
      "Bert Labs™ offers a dynamic, high-growth environment, fostering rapid career advancement and personal development.",
  },
  {
    title: "Stream-Agnostic Compensation",
    description:
      "We value all engineering disciplines equally, ensuring fair compensation for all as well as non-engineers.",
  },
  {
    title: "Innovation as a Way of Life",
    description:
      "Bert Labs™ fosters a culture of innovation, encouraging original thinking and research among all members, irrespective of their background.",
  },
  {
    title: "Breaking Societal Norms",
    description:
      "We actively challenge gender norms and encourage equal contributions from all members, both in the workplace and at home.",
  },
  {
    title: "Redefining Culture",
    description:
      "Bert Labs™ aims to dispel misconceptions about culture, emphasizing its connection to moral values and its role in shaping the organization's values and integrity.",
  },
];

const Careers = () => {
  const [jobs, setJobs] = useState([{
    _id: "66333b0d55c69868205608ac",
    title: "Lead Devops Engineer",
    department: "TECH",
    additionalInfo: "",
    createdAt: "2024-05-02T07:04:45.678Z",
    department: "TECH",
    experience: "2",
    isDeleted: false,
    location: "BANGALORE",
    noticePeriod: "30",
    requirements: "Skills - CI - CD, Ansible, AWS, Azure, Chef, Docker, Google Cloud, Jenkins, Kubernetes, Puppet\nUse programming/scripting in your day-to-day work\nHave shell experience - for example PowerShell on Window or BASH on Unix\nUse a Version Control System, preferably git\nConfiguration management tools such as Ansible/Chef/Puppet\nThe CLI/SDK/API of at least one public cloud (Azure, GCP, AWS) Scalability, HA and troubleshooting of web-scale tools like Terraform, CloudFormation CI/CD systems such as Jenkins\nContainer technologies such as Docker, Kubernetes\nDBs: Cassandra, SQL, NewSQL, ELK, MongoDB or other NoSQL systems\nExcellent communication and leadership skills.",
    responsibilities: "Deploy and scale Microservices application on Kubernetes and Docker\nDeploy, automate, and maintain web-scale infrastructure with leading public cloud vendors such as Microsoft Azure, Amazon Web Services, Google Cloud Platform\nTake charge of DevOps activities for CI/CD with the latest tech stacks. (AWS/Azure/Google) in the capacity of developer or architect\nDevise multi-region technical solutions, with the possibility of onsite assignments",
    skills: ["Python", "JavaScript"],
    summary: "Require new devops engineer",
    title: "Lead Devops Engineer",
    updatedAt: "2024-05-02T07:04:45.678Z",
    __v: 0
  }]);
  const [selected, setSelected] = useState("");
  const navigate=useNavigate()

  const getData = async () => {
    try {
      const storedJobs = sessionStorage.getItem("jobs"); // Change here
      if (storedJobs) {
        setJobs(JSON.parse(storedJobs));
      } else {
        const response = await axios.get(`https://bert-labs-website.onrender.com/admin/job`);
        setJobs(response.data.response);
        // Store job data in sessionStorage
        sessionStorage.setItem("jobs", JSON.stringify(response.data.response)); // Change here
      }
    } catch (err) {
      console.log(err);
    }
  };
  
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);
  
  useEffect(() => {
    getData();
  }, []);
  

  const filteredjobs = selected
    ? jobs.filter((elem) => elem.title === selected)
    : jobs;

  return (
    <Box
      paddingX={{ base: "7px", lg: "64px" }}
      paddingBottom={{ base: "7px", lg: "16px" }}
      fontFamily={"Mulish"}
    >
     <Box display={{ base: "none", lg: "block",md:"block" }}>
        <Box position={"relative"} height={{lg:"750px",md:"auto",base:"auto"}}>
          <Image src={frontImage} width={"100%"} />
          <Box
            position={"absolute"}
            left={"100px"}
            top={"77px"}
            fontWeight={"700"}
            fontSize={{xl:"60px",lg:"50px",md:"40px",base:"40px"}}
            width={"43%"}
          >
            Build your career and life!
          </Box>
          <Image
            src={hiringimage}
            position={"absolute"}
            right={{ lg: "50px", md: "50px" }}
            top={"77px"}
            height={{lg:"50%",md:"220px"}}
          />
          <Box
            position={"absolute"}
            left={"100px"}
            top={{xl:"320px",lg:"250px", md:"220px"}}
            fontWeight={"550"}
            fontSize={{xl:"35px",lg:"25px",md:"20px",base:"20px"}}
            width={"43%"}
          >
            Solve complex real world problems by building innovative products
            and platforms
          </Box>
        </Box>

        <Box mt={"56px"} fontSize={"36px"} fontWeight={"bold"}>
          Culture is the cornerstone of Bert Labs<span style={{ verticalAlign: "top", fontSize: "0.5em" }}>TM</span>, shaping our identity and
          values
        </Box>

        <Box padding={"24px"} mt={"24px"} display={"flex"}>
          <Box width={"40%"}>
            <Image src={bertteam} />
          </Box>
          <Box
            display={"grid"}
            gridTemplateColumns={"repeat(2,1fr)"}
            width={"60%"}
          >
            {values.map((elem) => {
              return (
                <Box padding={"7px"}>
                  <Box height={"30%"}>
                    <Text fontSize="24px" color={"red"}>
                      {elem.title}
                    </Text>
                  </Box>

                  <Box>
                    <Text mb={"10px"} fontSize={"16px"}>
                      {elem.description}
                    </Text>
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Box>
        <Box borderY={"3px solid #D63E3E"} padding={"15px"} m={"3% 0"}>
        At Bert Labs<span style={{ verticalAlign: "top", fontSize: "0.5em" }}>TM</span>, we believe in challenging the status quo every day. We do this with the power of diversity, inclusion, and collaboration. By connecting different perspectives, we can imagine new possibilities, inspire innovation, and release the full potential of our people. Our employee experience includes appreciation, belonging, growth, and purpose for everyone.
We are committed to a diverse and inclusive workplace. At Bert Labs<span style={{ verticalAlign: "top", fontSize: "0.5em" }}>TM</span>, we are an equal opportunity employer and do not discriminate on the basis of race, national origin, gender, gender identity, sexual orientation, disability, age, or other legally protected status.
Welcome to Bert Labs<span style={{ verticalAlign: "top", fontSize: "0.5em" }}>TM</span> family.
        </Box>

        <Box background={"#D9D8D8"} width={"100%"} padding={"24px"} mt={"16px"}>
          <Text width={"40%"} fontSize={"24px"}>
            Explore the areas where you can apply your unique skills
          </Text>
          <Box mt="27px" width={"52%"}>
            <Text fontSize={"36px"} fontWeight="bold">
              {" "}
              Job Roles{" "}
            </Text>
            <Box mt={"25px"}>
              <Select
                placeholder="Select job type"
                maxHeight="200px"
                overflowY="auto"
                border="3px solid gray"
                borderRadius="5px"
                backgroundColor="#fff"
                color="#333"
                fontSize="16px"
                padding="10px"
                _focus={{ border: "3px solid #3182ce", outline: "none" }}
                _hover={{ backgroundColor: "#f7fafc" }}
                value={selected}
                onChange={(event) => {
                  setSelected(event.target.value);
                }}
              >
                {[...new Set(jobs.map((elem) => elem.title))].map((title) => (
                  <option key={title} value={title}>
                    {title}
                  </option>
                ))}
              </Select>
            </Box>
          </Box>
        </Box>

        <Box
          display={"grid"}
          gridTemplateColumns={"repeat(2,1fr)"}
          m={"36px 0"}
          gap={"24px"}
        >
          {filteredjobs.map((elem, index) => {
            return (
              <Box
                padding={"24px"}
                display={"flex"}
                flexDirection={"column"}
                bg={"#DBECCB"}
                key={index}
                transition="all 0.3s ease-in-out"
                _hover={{
                  transform: "scale(1.05)",
                  boxShadow: "0px 0px 10px 5px rgba(0, 0, 0, 0.1)",
                }}
                borderRadius={"16px"}
              >
                <Box>
                  <Text fontWeight={"bold"} fontSize={"larger"}>
                    {elem.title}
                  </Text>
                </Box>
                <Box
                  display={"flex"}
                  justifyContent={"space-around"}
                  mt={"24px"}
                  flexDirection={"column"}
                >
                  <Text>
                    <Box as="span" fontWeight="bold">
                      Experience-
                    </Box>
                    <Box as="span" fontWeight="bold" color={"red"}>
                      {elem.experience}
                    </Box>
                  </Text>
                  <Text>
                    <Box as="span" fontWeight="bold">
                      Location-
                    </Box>
                    <Box as="span" fontWeight="bold" color={"red"}>
                      {elem.location}
                    </Box>
                  </Text>
                  <Text>
                    <Box as="span" fontWeight="bold">
                      Skills Required-
                    </Box>
                    <Box as="span" fontWeight="bold" color={"red"}>
                      {elem.skills.join(",")}
                    </Box>
                    
                  </Text>
                </Box>
                <Box display={"flex"}>
                <Button  margin={"auto"} mt={"27px"} bg={"#D63E3E"} color={"white"}   _hover={{ bg: "blue.500" }} onClick={()=>{navigate(`/careers/apply/${elem._id}`)}}>Apply</Button>
                
                </Box>
                
              
              </Box>
            );
          })}
        </Box>
        {/* <Box marginTop={"30px"} p={"0 5px"}>
          <Box margin={"auto"} fontWeight={"bold"} fontSize={"36px"}>
            Mail Us With
          </Box>

          <Box m={"24px 0"} display={"flex"} gap={"24px"}>
            <Box marginTop={"7px"} width={"87%"} marginX={"auto"}>
              <Box
                p={4}
                bg="white"
                borderRadius="md"
                position="relative"
                height={"140px"}
                border={"4px solid #FFB4B6"}
                textAlign={"center"}
              >
                <Icon
                  as={FaMapMarkerAlt}
                  color="red.500"
                  height={"43px"}
                  position="absolute" // Position the icon absolutely
                  top={0} // Adjust the top position as needed
                  left={-2} // Adjust the left position as needed
                  background={"white"}
                />
                <Text fontSize="25px" fontWeight="bold">
                  Bangalore - J.P Nagar
                </Text>
                <Text fontSize="lg" color="gray.600">
                  Work from office policy
                </Text>
              </Box>
            </Box>

            <Box marginTop={"7px"} width={"87%"} marginX={"auto"}>
              <Box
                p={4}
                bg="white"
                borderRadius="md"
                position="relative"
                height={"140px"}
                border={"4px solid #FFB4B6"}
                textAlign={"center"}
              >
                <Icon
                  as={FaFileAlt}
                  color="red.500"
                  height={"43px"}
                  position="absolute" // Position the icon absolutely
                  top={0} // Adjust the top position as needed
                  left={-2} // Adjust the left position as needed
                  background={"white"}
                />

                <Text fontSize="25px" fontWeight="bold">
                  Notice Period
                </Text>
              </Box>
            </Box>

            <Box marginTop={"7px"} width={"87%"} marginX={"auto"}>
              <Box
                p={4}
                bg="white"
                borderRadius="md"
                position="relative"
                height={"140px"}
                border={"4px solid #FFB4B6"}
                textAlign={"center"}
              >
                <Icon
                  as={FaEnvelope}
                  color="red.500"
                  width={"22px"}
                  height={"43px"}
                  position="absolute" // Position the icon absolutely
                  top={0} // Adjust the top position as needed
                  left={-2} // Adjust the left position as needed
                  background={"white"}
                />
                <Text fontSize="25px" fontWeight="bold">
                  Resume
                </Text>
                <Text fontSize="lg" color="gray.600">
                  Linkedin Profile
                </Text>
              </Box>
            </Box>
          </Box>
        </Box> */}
      </Box>

      <Box display={{ base: "block", md:"none",lg: "none" }}>
        <Box position={"relative"}>
          <Image src={"https://bertlabs-website.s3.ap-south-1.amazonaws.com/Vector.svg"} width={"100%"} />
          <Box
            position={"absolute"}
            left={{md:"50px",base:"20px"}}
            top={{md:"110px",base:"40px"}}
            fontWeight={"700"}
            fontSize={{md:"27px",base:"20px"}}
            width={"43%"}
            lineHeight={"27px"}
          >
            Build your career and life!
          </Box>
          <Image
            src={hiringimage}
            position={"absolute"}
            right={{md:"20px",base:"0px"}}
            top={{md:"100px",base:"40px"}}
            width={{md:"215px",base:"170px"}}
          />
          <Box
            position={"absolute"}
            left={{md:"50px",base:"20px"}}
            top={{md:"200px",base:"105px"}}
            fontWeight={"550"}
            fontSize={{md:"15px",base:"10px"}}
            width={"43%"}
          >
            Solve complex real world problems by building innovative products
            and platforms
          </Box>
        </Box>
        <Box padding={"25px"} display={"flex"}>
          <Box display={'flex'} justifyContent={'center'} width={"100%"}>
            <Image src={bertteam} width={"100%"}/>
          </Box>
        </Box>
        <Box>
          <Box background={"#FEB7B3"}>
            <Text
              display={"flex"}
              justifyContent={"center"}
              padding={"25px 25px 7px 25px"}
              fontSize={"17px"}
            >
              Explore the areas of work where you can apply your unique skills
            </Text>
            <Accordion allowMultiple padding={"10px"}>
              <AccordionItem>
                <h2>
                  <AccordionButton backgroundColor={"red"}>
                    <Box
                      as="span"
                      flex="1"
                      textAlign="left"
                      display={"flex"}
                      gap={"10px"}
                    >
                      <Box fontSize={"21px"} color={"white"}>
                        View Open Roles
                      </Box>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Box
                    display={"grid"}
                    gridTemplateColumns={"repeat(1,1fr)"}
                    mt={"34px"}
                    gap={"10px"}
                  >
                    {jobs.map((elem, index) => {
                      return (
                        <Box
                          padding={"10px"}
                          display={"flex"}
                          flexDirection={"column"}
                          bg={"#DBECCB"}
                          key={index}
                          transition="all 0.3s ease-in-out"
                          _hover={{
                            transform: "scale(1.05)",
                            boxShadow: "0px 0px 10px 5px rgba(0, 0, 0, 0.1)",
                          }}
                          borderRadius={"7px"}
                          width={"90%"}
                        >
                          <Box>
                            <Text fontWeight={"bold"} fontSize={"larger"}>
                              {elem.title}
                            </Text>
                          </Box>
                          <Box
                            display={"flex"}
                            justifyContent={"space-around"}
                            mt={"25px"}
                            flexDirection={"column"}
                          >
                            <Text>
                              <Box as="span" fontWeight="bold">
                                Experience-
                              </Box>
                              <Box as="span" fontWeight="bold" color={"red"}>
                                {elem.experience}
                              </Box>
                            </Text>
                            <Text>
                              <Box as="span" fontWeight="bold">
                                Location-
                              </Box>
                              <Box as="span" fontWeight="bold" color={"red"}>
                                {elem.location}
                              </Box>
                            </Text>
                            <Text>
                              <Box as="span" fontWeight="bold">
                                Skills Required-
                              </Box>
                              <Box as="span" fontWeight="bold" color={"red"}>
                                {elem.skills.join(",")}
                              </Box>
                            </Text>
                          </Box>
                          <Box display={"flex"}>
                               <Button  margin={"auto"} mt={"27px"} bg={"#D63E3E"}   _hover={{ bg: "blue.500" }}  color={"white"} onClick={()=>{navigate(`/careers/apply/${elem._id}`)}}>Apply</Button>
                
                          </Box>
                        
                        </Box>
                      );
                    })}
                  </Box>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </Box>

          <Box borderY={"3px solid #D63E3E"} padding={"15px"} mt={"15px"}>
        At Bert Labs<span style={{ verticalAlign: "top", fontSize: "0.5em" }}>TM</span>, we believe in challenging the status quo every day. We do this with the power of diversity, inclusion, and collaboration. By connecting different perspectives, we can imagine new possibilities, inspire innovation, and release the full potential of our people. Our employee experience includes appreciation, belonging, growth, and purpose for everyone.
We are committed to a diverse and inclusive workplace. At Bert Labs<span style={{ verticalAlign: "top", fontSize: "0.5em" }}>TM</span>, we are an equal opportunity employer and do not discriminate on the basis of race, national origin, gender, gender identity, sexual orientation, disability, age, or other legally protected status.
Welcome to Bert Labs<span style={{ verticalAlign: "top", fontSize: "0.5em" }}>TM</span> family.
        </Box>

        </Box>
        <Box boxShadow="0px 4px 15px rgba(0, 0, 0, 0.1)">
          <Box mt={"43px"}>
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              mt={"17px"}
            >
              <Box position={"relative"}>
                <Image src={vectornew} mt={"7px"} />
                <Box
                  position="absolute"
                  top="50%"
                  left={"40%"}
                  transform="translate(-50%, -50%) rotate(-45deg)"
                  color="white"
                  fontWeight="bold"
                  fontSize="11px"
                >
                  PERKS
                </Box>
              </Box>

              <Text fontSize={"21px"} mt={"7px"}>
                Benefits of working with us
              </Text>
            </Box>
          </Box>
          <Accordion allowMultiple padding={"21px"}>
            {values.map((elem) => {
              return (
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box
                        as="span"
                        flex="1"
                        textAlign="left"
                        display={"flex"}
                        gap={"17px"}
                      >
                        <Box>
                          <Image src={tick} />
                        </Box>
                        <Box>{elem.title}</Box>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>{elem.description}</AccordionPanel>
                </AccordionItem>
              );
            })}
          </Accordion>
        </Box>

      


      </Box>
    </Box>
  );
};

export default Careers;
