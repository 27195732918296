import { Box, Image, Button, Flex, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import Leftsidebar from './Leftsidebar';
import axios from 'axios';

const Showresources = () => {
  const [resources, setResources] = useState([]);

  const getData = async () => {
    try {
      let response = await axios.get("https://bert-labs-website.onrender.com/admin/resources");
      console.log(response);
      setResources(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://bert-labs-website.onrender.com/admin/resources/${id}`);
      // Update the state to remove the deleted resource
      setResources(resources.filter(resource => resource._id !== id));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Flex>
      <Box width="50%">
        <Leftsidebar />
      </Box>
      <Box width="50%"  ml={"-10%"} mb={"10px"}>
        {resources.map((resource, index) => (
          <Box key={index} border="1px solid gray" borderRadius="md" p={4} mb={4}>
            <Image src={resource.imageUrl} alt={resource.image} />
            <Box mt={4}>
              <Text fontSize="lg">{resource.paragraph}</Text>
              <Text>{resource.text}</Text>
              <Text>{resource.link}</Text>
              <Text>Category: {resource.category}</Text>
              <Text>Created At: {new Date(resource.createdAt).toLocaleString()}</Text>
              <Text>Updated At: {new Date(resource.updatedAt).toLocaleString()}</Text>
              <Button colorScheme="red" mt={4} onClick={() => handleDelete(resource._id)}>Delete</Button>
            </Box>
          </Box>
        ))}
      </Box>
    </Flex>
  );
};

export default Showresources;
