import React, { useState } from 'react';
import { Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Box } from '@chakra-ui/react';
import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import Bertnova from './Bertnova';
import Bertoptimus from './Bertoptimus';
import Bertmaximus from './Bertmaximus';
import Bertqurios from './Bertqurios';
import Bertgeminos from './Bertgeminos';
import ovenbert from "../../assets/About/ed-oven.svg";
import Devices from '../Card/Devices';

const CustomAccordion = ({industry}) => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const handleToggle = (index) => {
    if (index === expandedIndex) {
      setExpandedIndex(null);
    } else {
      setExpandedIndex(index);
    }
  };

  return (
    <Accordion allowMultiple={true}>
      <AccordionItem isExpanded={expandedIndex === 1}>
        {({ isExpanded }) => (
          <>
            <h2>
              <AccordionButton onClick={() => handleToggle(1)}>
                <Box as="span" flex="1" textAlign="left">
                  Bert Maximus<span style={{ verticalAlign: "top", fontSize: "0.5em" }}>&#174;</span>
                </Box>
                {isExpanded ? (
                  <MinusIcon fontSize="12px" />
                ) : (
                  <AddIcon fontSize="12px" />
                )}
              </AccordionButton>
            </h2>
            <AccordionPanel pb={7} display={isExpanded ? 'block' : 'none'}>
              <Devices />
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
      <AccordionItem isExpanded={expandedIndex === 2}>
        {({ isExpanded }) => (
          <>
            <h2>
              <AccordionButton onClick={() => handleToggle(2)}>
                <Box as="span" flex="1" textAlign="left">
                  Bert Qurious
                </Box>
                {isExpanded ? (
                  <MinusIcon fontSize="12px" />
                ) : (
                  <AddIcon fontSize="12px" />
                )}
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4} display={isExpanded ? 'block' : 'none'}>
              <Bertqurios />
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
      <AccordionItem isExpanded={expandedIndex === 3}>
        {({ isExpanded }) => (
          <>
            <h2>
              <AccordionButton onClick={() => handleToggle(3)}>
                <Box as="span" flex="1" textAlign="left">
                  Bert Nova<span style={{ verticalAlign: "top", fontSize: "0.5em" }}>TM</span>
                </Box>
                {isExpanded ? (
                  <MinusIcon fontSize="12px" />
                ) : (
                  <AddIcon fontSize="12px" />
                )}
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4} display={isExpanded ? 'block' : 'none'}>
              <Bertnova />
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
      <AccordionItem isExpanded={expandedIndex === 4}>
        {({ isExpanded }) => (
          <>
            <h2>
              <AccordionButton onClick={() => handleToggle(4)}>
                <Box as="span" flex="1" textAlign="left">
                  Bert Geminus<span style={{ verticalAlign: "top", fontSize: "0.5em" }}>TM</span>
                </Box>
                {isExpanded ? (
                  <MinusIcon fontSize="12px" />
                ) : (
                  <AddIcon fontSize="12px" />
                )}
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4} display={isExpanded ? 'block' : 'none'}>
              <Bertgeminos text={industry.bert_geminus.desc} image={industry.bert_geminus.img} />
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
      <AccordionItem isExpanded={expandedIndex === 5}>
        {({ isExpanded }) => (
          <>
            <h2>
              <AccordionButton onClick={() => handleToggle(5)}>
                <Box as="span" flex="1" textAlign="left">
                  Bert Optimus<span style={{ verticalAlign: "top", fontSize: "0.5em" }}>&#174;</span>
                </Box>
                {isExpanded ? (
                  <MinusIcon fontSize="12px" />
                ) : (
                  <AddIcon fontSize="12px" />
                )}
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4} display={isExpanded ? 'block' : 'none'}>
              <Bertoptimus text={industry.bert_optimus.desc} image={industry.bert_optimus.img}/>
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    </Accordion>
  );
};

export default CustomAccordion;