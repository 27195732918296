import React, { useState } from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Button,
  Textarea,
} from '@chakra-ui/react';
import { Text } from '@chakra-ui/react';
import axios from 'axios'

function Formcomponent({setShowForm}) {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    organization: '',
    designation: '',
    email: '',
    contactNo: '',
    linkedinProfile: 'linkedin',
    message: '',
  });

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSubmit = async(event) => {
    event.preventDefault();
    console.log('Form Data:', formData);
    try{
    let data=await axios.post("https://bert-labs-website.onrender.com/wp",formData)
    console.log(data)
    setFormData({
      firstName: '',
      lastName: '',
      organization: '',
      designation: '',
      email: '',
      contactNo: '',
      linkedinProfile: '',
      message: '',
    })
    setShowForm(false)
    }
    catch(err){
      console.log(err)
    }
    // You can submit the form data to your backend here
  };

  return (
    <Box   w="full" padding={"25px"} maxW="7xl" mx="auto" py={4} width={{lg:"70%",base:"90%"}} borderRadius={"25px"} position={"absolute"} top={{lg:"7%",base:"10px"}} background={"white"} left={{lg:"17%",base:"4%"}} zIndex={"100"}>
        <Text>
        Please fill the required details
        </Text>
      <Box as="form" onSubmit={handleSubmit}>
        <Box display={"flex"} gap={{ lg: "34px", md: "16px", base: "10px" }} flexDirection={{base:"column",lg:"row"}}>
        <FormControl isRequired mt={4}>
          {/* <FormLabel htmlFor="firstName">First Name</FormLabel> */}
          <Input
            id="firstName"
            name="firstName"
            placeholder="First Name"
            value={formData.firstName}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl isRequired mt={4}>
          {/* <FormLabel htmlFor="lastName">Last Name</FormLabel> */}
          <Input
            id="lastName"
            name="lastName"
            placeholder="Last Name"
            value={formData.lastName}
            onChange={handleChange}
          />
        </FormControl>

        </Box>
     
        <Box display={"flex"} flexDirection={{base:"column",lg:"row"}} gap={{ lg: "34px", md: "16px", base: "10px" }}>
        <FormControl mt={4}>
          {/* <FormLabel htmlFor="organization">Organization</FormLabel> */}
          <Input
            id="organization"
            name="organization"
            placeholder="Organization"
            value={formData.organization}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl mt={4}>
          {/* <FormLabel htmlFor="designation">Designation</FormLabel> */}
          <Input
            id="designation"
            name="designation"
            placeholder="Designation"
            value={formData.designation}
            onChange={handleChange}
          />
        </FormControl>
        </Box>
        <Box display={"flex"} gap={{ lg: "34px", md: "16px", base: "10px" }} flexDirection={{base:"column",lg:"row"}}>

      
        <FormControl isRequired mt={4}>
          
          {/* <FormLabel htmlFor="email">Email ID</FormLabel> */}
          <Input
            id="email"
            name="email"
            placeholder="Email ID"
            value={formData.email}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl mt={4}>
          {/* <FormLabel htmlFor="contactNo">Contact No</FormLabel> */}
          <Input
            id="contactNo"
            name="contactNo"
            placeholder="Contact No"
            value={formData.contactNo}
            onChange={handleChange}
          />
        </FormControl>
        </Box>
        {/* <FormControl mt={4}>
        
          <Input
            id="linkedinProfile"
            name="linkedinProfile"
            placeholder="Linkedin Profile"
            value={formData.linkedinProfile}
            onChange={handleChange}
          />
        </FormControl> */}
        <FormControl mt={4}>
          {/* <FormLabel htmlFor="message">Anything specific you want to ask?</FormLabel> */}
          <Textarea
            id="message"
            name="message"
            placeholder="Anything specific you want to ask?"
            value={formData.message}
            onChange={handleChange}
          />
        </FormControl>
        
        <Button colorScheme={"red"}
                marginRight={"20px"}
                fontSize={{ lg: "16px", md: "16px", base: "16px" }}  type="submit" mt={{ lg: 4, base: 2 }}>
          Submit
        </Button>

        <Button 
                marginRight={"20px"}
                fontSize={{ lg: "16px", md: "16px", base: "16px" }} type="submit" mt={{ lg: 4, base: 2 }}
                onClick={()=>setShowForm(false)}
                // padding={{base:"20px"}}
                >
              
          Cancel
        </Button>
        
      </Box>
    </Box>
  );
}

export default Formcomponent;