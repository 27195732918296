import { Box,Image,Text } from '@chakra-ui/react'
import React from 'react'
import bertqurios from "../../assets/About/bertqurios.svg"
import Qrios1 from "../../assets/About/qrios1.png"
import Qrios2 from "../../assets/About/qrios2.png"
import firstqrious from "../../assets/About/devicon_networkx.png"
import secondqrious from "../../assets/About/mdi_router-wireless-settings.png"
import thirdqrious from "../../assets/About/qrios2.png"
const Bertqurios = () => {

    const data = [
        {
          image: firstqrious,
          text: "Multi-Hop Energy efficient 6LoWPAN Tree Network"
        },
        {
          image: secondqrious,
          text: "Over the Air updates (OTA) of devices"
        },
        {
          image: thirdqrious,
          text: "Root of Trust (RoT) security mechanism"
        }
      ];

  return (
   <Box marginTop={"70px"} padding={{sm:"0px"}} fontFamily={"Mulish"}>
    <Box   fontFamily={"Mulish"} display={{base:"none",lg:"block"}}  fontSize={{base:"24px",lg:"32px"}} fontWeight={"bold"}>
        Bert Qrious
                <span style={{ verticalAlign: "top", fontSize: "0.5em" }}>&#174;</span>
    </Box>
       <Box fontSize={{lg:"20px",base:"16px"}} mt={{lg:"25px",base:"-70px"}} >
       Bert Qrious<span style={{ verticalAlign: "top", fontSize: "0.5em" }}>&#174;</span> is proprietary, patented IoT powered Wireless Sensor Network, with several salient features like data security and remote software updates. Bert Qrious<span style={{ verticalAlign: "top", fontSize: "0.5em" }}>&#174;</span> gives Bert Platform Solution<span style={{ verticalAlign: "top", fontSize: "0.5em" }}>TM</span> the ability to real time transmit data.
       </Box>
       <Box display={"flex"} justifyContent={"center"} >
       <Image src={bertqurios}  height={{lg:"700px"}} />
       </Box>

       <Box
      display={{ base: "flex", lg: "flex" }}
      flexDirection={{ base: "column", lg: "row" }}
      gap={{ lg: "4%", sm: "10px" }}
      padding={"40px"}
    >
      {data.map((item, index) => (
        <Box
          key={index}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          width={{ base: "100%", lg: "30%" }}
          boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
          padding={"27px"}
        >
          <Image src={item.image} height={"50%"} />
          <Text
            fontSize={{ base: "10px", lg: "21px" }}
            alignItems={"center"}
            height={"50%"}
            mt={"34px"}
          >
            {item.text}
          </Text>
        </Box>
      ))}
    </Box>

   </Box>
  )
}

export default Bertqurios