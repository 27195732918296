import React from "react";
import { Box, Image, Button, Text, Center } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Home.css";
import PlayIcon from "../assets/Icons/play_icon.svg";
import BTGCard from "../Components/Card/BTGCard";
import BPSCard from "../Components/Card/BPSCard";
import SCPCard from "../Components/Card/SCPCard";
import ContactCard from "../Components/Card/ContactCard";
import ImageSlider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Formcomponent from "../Components/Forms/Formcomponent";
import CarbonFootprintCal from "../Components/Forms/CarbonFootprintCal";
import { color } from "framer-motion";
import { useNavigate } from "react-router-dom";
import ReactGA from 'react-ga';

const Home = () => {
  const [showForm, setShowForm] = useState(false);
  const [showInt, setShowInt] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const images = [
    {
      link: "https://bertlabs-website.s3.ap-south-1.amazonaws.com/main_img.png",
    },
  ];

  const [currentSlide, setCurrentSlide] = useState(0);
  const transitionTime = 120000; // 1 minute

  useEffect(() => {
    const interval = setInterval(() => {
      const nextSlide = (currentSlide + 1) % images.length;
      setCurrentSlide(nextSlide);
    }, transitionTime);

    return () => clearInterval(interval);
  }, [currentSlide, images.length, transitionTime]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowInt(true);
    }, 60000); // 1 minute

    return () => clearTimeout(timer);
  }, []);


  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    initialSlide: currentSlide,
  };

  return (
    <Box>
      {showInt && (
        <Box
          width={{ lg: "34%", base: "80%", md: "47%" }}
          position={"fixed"}
          top="200px"
          zIndex={"100"}
          background={"white"}
          left={{ lg: "34%", base: "14%" }}
          padding={"17px"}
          borderRadius={"25px"}
          // border={"7px solid red"}
        >
          <Box display={"flex"} justifyContent={"center"}>
            <Image src="https://bertlabs-website.s3.ap-south-1.amazonaws.com/streamline_information-desk.png" />
          </Box>
          <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
            <Box mt={"10px"}>
              <Text>Any specific information you're looking for?</Text>
            </Box>
            <Box display={"flex"} gap={"17px"} mt={"15px"}>
              <Button
                colorScheme={"red"}
                fontSize={{ lg: "16px", md: "16px", base: "12px" }}
                onClick={()=>{navigate("/demo/demo")}}
              >
                Reach out to me{" "}
              </Button>
              <Button
                onClick={() => {
                  setShowInt(false);
                }}
                fontSize={{ lg: "16px", md: "16px", base: "12px" }}
              >
                No Thank You
              </Button>
            </Box>
          </Box>
        </Box>
      )}

      <Box fontFamily="Mulish" opacity={showInt ? 0.2 : 1}>
        <Box
          margin={{ lg: "0% 5% 0% 5%", md: "0% 5% 0% 5%", base: "0% 2% 0% 2%" }}
        >
          <ImageSlider {...settings}>
            {images.map((image, index) => (
              <Box
                key={index}
                position={"relative"}
                display={"flex"}
                justifyContent={"center"}
                height={{ base: "700px" }}
              >
                <Image
                  src={image.link}
                  alt={`Slide ${index + 1}`}
                  width={"100%"}
                  height={"100%"}
                  // height={{ lg: "700px", md: "500px", base: "250px" }}
                  objectFit={{ lg: "fill", md: "cover", base: "cover" }}
                  className="home-image"
                />

                <Box
                  width={{ lg: "60%", md: "60%", base: "83%" }}
                  position={"absolute"}
                  top={"10%"}
                  left={"5%"}
                >
                  <Box
                    className="small-text"
                    marginBottom="5%"
                    fontFamily="Mulish"
                  >
                    Welcome to Bert Labs<span style={{ verticalAlign: "top", fontSize: "0.5em" }}>TM</span>
                  </Box>

                  <Box
                    className="welcome-text"
                    marginBottom={"5%"}
                    fontFamily="Mulish"
                  >
                    Innovation at
                    <br /> the intersection of
                    <br />
                    <span className="red-text">AI & IoT</span>
                  </Box>
                  <Box display={"flex"}>
                    <>
                      <Button
                        colorScheme={"red"}
                        marginRight={"20px"}
                        fontSize={{ lg: "16px", md: "16px", base: "12px" }}
                      >
                        <Text
                          marginRight={"3px"}
                          onClick={() => {
                            navigate("/demo/demo");
                          }}
                        >
                          Request Demo
                        </Text>
                        <img
                          className="img-logo"
                          src={PlayIcon}
                          alt="play-icon"
                        />
                      </Button>
                    </>
                    <Link to="/products">
                      <Button
                        colorScheme={"transparent"}
                        border={"1px solid white"}
                        fontSize={{ lg: "16px", md: "16px", base: "12px" }}
                      >
                        Discover Products
                      </Button>
                    </Link>
                  </Box>
                </Box>
              </Box>
            ))}

            <Box
              position={"relative"}
              display={"flex"}
              justifyContent={"center"}
              height={{ base: "700px" }}
              // color={showForm ? "red" : "gray"}
              background={showForm ? "gray" : "inherit"}
            >
              <Image
                src={
                  "https://bertlabs-website.s3.ap-south-1.amazonaws.com/iStock-1061190094+1.png"
                }
                height={"100%"}
                width={"100%"}
                // height={{ lg: "700px", md: "500px", base: "250px" }}
                objectFit={{ lg: "fill", md: "cover", base: "cover" }}
                className="home-image"
                opacity={showForm ? 0.2 : 1}
              />

              <Box
                width={{ lg: "40%", md: "40%", base: "87%" }}
                position={"absolute"}
                top={"10%"}
                left={"5%"}
                background={"#00000080"}
                padding={"20px"}
                opacity={showForm ? 0.2 : 1}
              >
                {/* <Box
                  className="small-text"
                  marginBottom="5%"
                  fontFamily="Mulish"
                >
                  Welcome to Bert Labs<span style={{ verticalAlign: "top", fontSize: "0.5em" }}>TM</span>
                </Box> */}

                <Box
                  className="welcome-text"
                  fontSize={{lg:"50px",md:"40px",base:"30px"}}
                  marginBottom={"5%"}
                  fontFamily="Mulish"
                >
                  Business Transformation Impact on Energy, Net Zero, Productivity, Asset Integrity
                </Box>
                <Box display={"flex"}>
                  <Button
                    colorScheme={"red"}
                    marginRight={"20px"}
                    fontSize={{ lg: "16px", md: "16px", base: "12px" }}
                    onClick={() => {
                      setShowForm(true);
                    }}
                  >
                    <Text marginRight={"3px"}>Download Brochure</Text>
                  </Button>
                </Box>
              </Box>
              {showForm && <Formcomponent setShowForm={setShowForm} />}
            </Box>
            {/* <Box
              position={"relative"}
              display={"flex"}
              justifyContent={"center"}
              height={{ base: "700px" }}
              // color={showForm ? "red" : "gray"}
              background={showForm ? "gray" : "inherit"}
            >
              <Image
                src={
                  "https://bertlabs-website.s3.ap-south-1.amazonaws.com/iStock-1061190094+1.svg"
                }
                height={"100%"}
                width={"100%"}
                // height={{ lg: "700px", md: "500px", base: "250px" }}
                objectFit={{ lg: "cover", md: "cover", base: "cover" }}
                className="home-image"
                opacity={showForm ? 0.2 : 1}
              />

              <Box
                width={{ lg: "40%", md: "40%", base: "87%" }}
                position={"absolute"}
                top={"10%"}
                left={"5%"}
                background={"#00000080"}
                padding={"10px"}
                opacity={showForm ? 0.2 : 1}
              >
                <Box
                  className="welcome-text"
                  marginBottom={"5%"}
                  fontFamily="Mulish"
                >
                  Bert Labs<span style={{ verticalAlign: "top", fontSize: "0.5em" }}>TM</span> Impact on Carbon footprint
                </Box>
                <Box display={"flex"}>
                  <Button
                    colorScheme={"red"}
                    marginRight={"20px"}
                    fontSize={{ lg: "16px", md: "16px", base: "12px" }}
                    onClick={() => {
                      setShowForm(true);
                    }}
                  >
                    <Text marginRight={"3px"}>Learn More</Text>
                  </Button>
                </Box>
              </Box>
              {showForm && <CarbonFootprintCal setShowForm={setShowForm} />}
            </Box> */}
          </ImageSlider>

          <Box margin={{ lg: "40px", md: "30px", base: "20px" }} zIndex={"10"}>
            <Box display={"flex"}>
              <Text
                fontSize={{ lg: "36px", md: "38px", base: "18px" }}
                fontWeight={{ lg: "900", md: "800", base: "700" }}
                margin={"20px 0px"}
              >
                Achieving Business Transformation Goals
              </Text>
            </Box>
            <BTGCard />
          </Box>

          <Box>
            <Box display={"flex"} fontFamily="Mulish">
              <Text
                fontSize={{ lg: "36px", md: "38px", base: "18px" }}
                fontWeight={{ lg: "900", md: "800", base: "700" }}
                margin={"20px 25px"}
              >
                Bert Platform Solution
                <span style={{ verticalAlign: "top", fontSize: "0.5em" }}>TM</span>
              </Text>
            </Box>
            <Box>
              <BPSCard />
            </Box>
          </Box>

          <Box>
            <SCPCard />
          </Box>

          <Box paddingBottom={"25px"}>
            <ContactCard />
          </Box>
          {/* </section> */}
        </Box>
      </Box>
    </Box>
  );
};

export default Home;
