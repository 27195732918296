import { useState } from 'react';
import { FormControl, FormLabel, Input, Textarea, Button, Select, Box } from '@chakra-ui/react';
import axios from 'axios';
import Leftsidebar from './Leftsidebar';

function ResourceForm() {
  const [image, setImage] = useState(null);
  const [paragraph, setParagraph] = useState('');
  const [text, setText] = useState('');
  const [category, setCategory] = useState('');
  const [link,setLink] = useState('');
  const [date, setDate] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData=new FormData();
    
    formData.append("image",image);
    formData.append("paragraph",paragraph);
    formData.append("text",text);
    formData.append("category",category);
    formData.append('link',link);
    formData.append('date', date);
    console.log(formData)
    try {
      const data = await axios.post("https://bert-labs-website.onrender.com/admin/resources", formData, { headers: { 'Content-Type': 'multipart/form-data' } });
      console.log(data);
      // Handle success, e.g., show a success message or redirect the user
    } catch (error) {
      console.error('Error submitting form:', error);
      // Handle error, e.g., show an error message to the user
    }
  };

  return (
    <Box display={"flex"}>
      <Box width={"50%"}>
        <Leftsidebar/>
      </Box>
    
      <Box width={"50%"} ml={"-10%"} mb={"20px"}>
        <form onSubmit={handleSubmit}>
          <FormControl>
            <FormLabel>Image</FormLabel>
            <Input paddingX={"10px"} type="file" accept="image/*" onChange={(e) => setImage(e.target.files[0])} />
          </FormControl>
          <FormControl>
            <FormLabel>Paragraph</FormLabel>
            <Textarea value={paragraph} onChange={(e) => setParagraph(e.target.value)} />
          </FormControl>
          <FormControl>
            <FormLabel>Text</FormLabel>
            <Textarea value={text} onChange={(e) => setText(e.target.value)} />
          </FormControl>
          <FormControl>
            <FormLabel>Link</FormLabel>
            <Textarea value={link} onChange={(e) => setLink(e.target.value)} />
          </FormControl>
          <FormControl>
            <FormLabel>Category</FormLabel>
            <Select value={category} onChange={(e) => setCategory(e.target.value)}>
              <option value="">Select</option>
              <option value="Bertlabs IPR">Bertlabs IPR</option>
              <option value="Case Studies">Case Studies</option>
              <option value="Product Launches">Product Launches</option>
              <option value="Media and Press">Media and Press</option>
              <option value="Awards">Awards and Recognitions</option>
              {/* Add more options as needed */}
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel>Date</FormLabel>
            <Input type="date" value={date} onChange={(e) => setDate(e.target.value)} />
          </FormControl>
          <Button mt={4} colorScheme="teal" type="submit">
            Submit
          </Button>
        </form>
      </Box>
    </Box>
  );
}

export default ResourceForm;
