import React, { useEffect } from 'react'
import { useState } from 'react';
import { Box, Button,Text, FormControl, FormLabel, Input, Textarea, VStack, background } from '@chakra-ui/react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom';

import Jobinput from './Jobinput';
import Showjobs from './Showjobs';
import Resourceinput from './Resourceinput';
import Showresources from './Showresources';





const Leftsidebar = () => {
 
  
  const [showDescription, setShowDescription] = useState(false);
  const [showResponse, setShowResponse] = useState(false);
  const [showCreateJob, setShowCreateJob] = useState(false);
  const [resources,setResources] = useState(false)
  const [bresources,setBresources] = useState(false)
  const [showPeople,setShowPeople] = useState(false)
  const [showcontacts,setShowcontacts] = useState(false)
  const [showbrochure,setShowbrochure] = useState(false)



  const navigate=useNavigate()


  const handleJobClick = () => {
    setShowDescription((prev) => !prev);
    setShowResponse((prev) => !prev);
    setShowCreateJob((prev) => !prev);
  };
  const handleResourcesClick=()=>{
    console.log("clicked")
    setResources((prev)=>!prev)
    setBresources((prev)=>!prev)

  }
  const handlePeopleclick=()=>{
    setShowPeople((prev)=>!prev)
  }
  const handleContactclick=()=>{
    setShowcontacts((prev)=>!prev)
  }
  const handleBrochureclick=()=>{
    setShowbrochure((prev)=>!prev)
  }

  const handleLogout=()=>{
    console.log("logout")
    localStorage.removeItem("token");
    navigate("/login")
  }
 


  return (
    <Box mb={"10vh"}>
   
    <Box display={{base:"none",lg:"block"}}>
   <Box display={"flex"} justifyContent={"space-between"} padding={"25px"}>
    <Box width={"70%"} >
    <VStack spacing={4} align="stretch">
      <Box bg="lightgray" p={4} background={"#D63E3E"}>
        <Button onClick={handleJobClick} color={"black"} >Job</Button>
      </Box>
      {showDescription && (
        <Box  px={4} >
          <Button background={"white"} onClick={(prev)=>{
          navigate("/admin/jobs")
          }}   fontWeight={"bold"}>Job Description</Button>
          {/* Include your job description component here */}
        </Box>
      )}
    
      {showCreateJob && (
        <Box  px={4}   >
          <Button 
          background={"white"}
            onClick={()=>{
             navigate("/admin/createjobs");
            }}
            fontWeight={"bold"}
          >Create Job</Button>
          {/* Include your create job component here */}
        </Box>
      )}
    </VStack>
    <VStack spacing={4} align="stretch" mt={2}>
      <Box bg="lightgray" p={4} color={"black"} background={"#D63E3E"}>
        <Button onClick={handleResourcesClick}>Resources</Button>
      </Box>
      {resources && (
        <Box  px={4} fontWeight={"bold"}>
          <Button fontWeight={"bold"} background={"white"}
             onClick={()=>{
              navigate("/admin/createresources")
            }}
          >Add new Resources</Button>
          {/* Include your job description component here */}
        </Box>
      )}
      {bresources && (
        <Box  px={4} fontWeight={"bold"}>
          <Button fontWeight={"bold"} background={"white"}
             onClick={()=>{
        navigate("/admin/resources")
            }}
          >Bert Resources</Button>
          {/* Include your job response component here */}
        </Box>
      )}
    </VStack>
    <VStack spacing={4} align="stretch" mt={"2"}>
      <Box bg="lightgray" p={4} background={"#D63E3E"}>
        <Button onClick={handlePeopleclick} color={"black"}>People</Button>
      </Box>
      {showPeople && (
        <Box  px={4} >
          <Button background={"white"} onClick={(prev)=>{
          navigate("/admin/applicants")
          }}   fontWeight={"bold"}>Applicants</Button>
          {/* Include your job description component here */}
        </Box>
      )}
    
   
    </VStack>
    <VStack spacing={4} align="stretch" mt={"2"}>
      <Box bg="lightgray" p={4} background={"#D63E3E"}>
        <Button onClick={handleContactclick} color={"black"}>Contacts</Button>
      </Box>
      {showcontacts && (
        <Box  px={4} >
          <Button background={"white"} onClick={(prev)=>{
          navigate("/admin/contacts")
          }}   fontWeight={"bold"}>Contacts</Button>
          {/* Include your job description component here */}
        </Box>
      )}
    
   
    </VStack>
    <VStack spacing={4} align="stretch" mt={"2"}>
      <Box bg="lightgray" p={4} background={"#D63E3E"}>
        <Button onClick={handleBrochureclick} color={"black"}>Brochure</Button>
      </Box>
      {showbrochure && (
        <Box  px={4} >
          <Button background={"white"} onClick={(prev)=>{
          navigate("/admin/brochure")
          }}   fontWeight={"bold"}>Request</Button>
          {/* Include your job description component here */}
        </Box>
      )}
    
   
    </VStack>
    <VStack spacing={4} align="stretch" mt={"2"}>
      <Box bg="lightgray" p={4} background={"gray"}>
        <Button onClick={handleLogout} bg={"#D63E3E"} color={"white"} 
        
        >Logout</Button>
      </Box>
  
    
   
    </VStack>
    
    </Box>



 


   </Box>
   </Box>
   </Box>
   

  )
}

export default Leftsidebar
