import React, {useState} from "react";
import { Box, Text, Image, Button, Heading } from "@chakra-ui/react";
import play from "../assets/About/play.png";
import Bertqurios from "../Components/Bertmaximus/Bertqurios";
import Bertnova from "../Components/Bertmaximus/Bertnova";
import Bertoptimus from "../Components/Bertmaximus/Bertoptimus";
import Businesstransform from "../Components/Bertmaximus/Businesstransform";
import CustomAccordion from "../Components/Bertmaximus/Accrodianproducts";
import Bertgeminos from "../Components/Bertmaximus/Bertgeminos";
import Devices from "../Components/Card/Devices";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import {Link,useParams} from 'react-router-dom'


const Industries = ({ industry }) => {
  // console.log(industry);
const params=useParams()
// console.log(params,"params",industry)

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, [params]);


  const navigate=useNavigate()
  return (
    <Box fontFamily={"Mulish"} p={{ base: "16px", lg: "0 5%" }} mt={"10px"}>
      <Box>
        <Box>
          <Box
            margin={"auto"}
            width={"100%"}
            display={"flex"}
            fontFamily={"Mulish"}
            justifyContent={"center"}
            alignItems={"center"}
            fontSize={{ base: "25px",md:"30px", lg: "40px" }}
          >
            <Text fontWeight={"bold"}>Bert Platform Solution
                <span style={{ verticalAlign: "top", fontSize: "0.5em" }}>TM</span></Text>
          </Box>
          <Box
            display={"flex"}
            mt={"10px"}
            flexDirection={{ base: "column",md:"column", lg: "row" }}
          >
            <Box
              borderRadius="lg"
              // p="4"
              mb="4"
              width={{ lg: "60%", base: "100%" }}
            >
              <Text
                fontSize={{ lg: "28px",md:"24px", base: "20px" }}
                fontWeight="bold"
                mb="4"
              >
                {industry.title}
              </Text>
              <Text fontSize={{base:"16px",md:"18px",lg:"20px"}}>
                •{" "}
                <Text as="span" fontWeight="bold">
                  Bert Platform Solution<span style={{ verticalAlign: "top", fontSize: "0.5em" }}>TM</span>{" "}
                </Text>
                {industry.main_content.bps}
              </Text>
              <Text fontSize={{base:"16px",md:"18px",lg:"20px"}}>
                •{" "}
                <Text as="span" fontWeight="bold">
                  Bert Geminus<span style={{ verticalAlign: "top", fontSize: "0.5em" }}>TM</span> Operational Digital Twin{" "}
                </Text>
                {industry.main_content.geminus}
              </Text>
              <Text fontSize={{base:"16px",md:"18px",lg:"20px"}}>
                •{" "}
                <Text as="span" fontWeight="bold">
                  Bert Geminus<span style={{ verticalAlign: "top", fontSize: "0.5em" }}>TM</span> Operational Digital Twin{" "}
                </Text>
                {industry.main_content.optimus}
              </Text>
            </Box>
            <Box
              width={{ lg: "40%", base: "100%" }}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              // mt={{ lg: "3%", base: "0" }}
              // ml={{ lg: "10%", base: "0%" }}
            >
              <Image src={industry.main_content.img_main} height={"300px"} />
            </Box>
          </Box>

          <Box display="flex" marginTop={"25px"}>
            <Button
              colorScheme="#d63e3e"
              mr={{ lg: "8", sm: "0" }}
              className="button"
              marginLeft={{ lg: "20px", sm: "0" }}
              alignContent={"center"}
              gap={"10px"}
              onClick={()=>{navigate("/demo/demo")}}
            >
              <Text>Request Demo</Text>

              <Image src={play} />
            </Button>
            <Button ml={{ base: "10px" }} onClick={()=>{navigate("/products")}}>Discover Products</Button>
          </Box>
        </Box>
      </Box>

      <Box
        marginTop={"36px"}
        // padding={{ lg: "70px 40px 50px 70px", base: "24px" }}
      >
        <Text
          fontSize={{ lg: "40px", base: "24px" }}
          color={{ base: "black", lg: "gray" }}
          fontWeight={{ base: "bold", lg: "normal" }}
        >
          Solution
        </Text>
        <Text fontSize={{ lg: "40px", md: "20px" }}>
          {industry.elemdata}
        </Text>
        <Box>
          <Box
          display={"flex"}
            justifyContent={"center"}
            // minHeight={"600px"}
            p={{ lg: "0 5%", md: "0 2%", base: "0 2%" }}
            mt={{ base: "25px", lg: "20px" }}
            top={"50%"}
          >
            <Image src={industry.solution.digital_twin[0]} />
          </Box>
        </Box>
      </Box>

      <Box fontSize={"25px"} mt={"36px"}>
        <Text ml={"4px"} fontSize={"36px"} color={"gray"}>
          Impact
        </Text>
        <Box display={"flex"} justifyContent={"space-between"} height={{lg:"180px",md:"160px",base:"120px"}} border={"1px solid #ccc"} borderRadius={"8px"} boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" width={{lg:"600px",md:"80%",base:"100%"}} p={"2%"}>
          <Box display={"flex"} justifyContent={"space-around" }>
            <Box>
            <Text mt={{lg:"15px",md:"8px",base:"4px"}} fontSize={{lg:"40px", md:"36px",base:"30px"}} fontFamily={"satoshi"} fontWeight={"700"} lineHeight={"57px"} bgGradient={"linear-gradient(93.41deg, #E95B5B 0.6%, #C21818 100%)"} bgClip={"text"}
>{industry.solution.impact.energy_savings}<Box as="span" fontSize={"0.5em"}>M</Box></Text>
            <Text fontSize={{lg:"0.6em",md:"0.55em",base:"0.4em"}}>Annual energy savings</Text>
            </Box>
            <Box p={"5px"}><Image  src="https://bertlabs-website.s3.ap-south-1.amazonaws.com/impact/energy_savings.png"  height={{lg:"100px",md:"85px",base:"70px"}}/></Box>
            
          </Box>
          <Box border={"1px solid #ccc"} m={"0 3px"}></Box>
          <Box display={"flex"} justifyContent={"space-around" }>
            <Box>
            <Text mt={{lg:"15px",md:"8px",base:"4px"}} fontSize={{lg:"40px", md:"36px",base:"30px"}} fontFamily={"satoshi"} fontWeight={"700"} lineHeight={"57px"} bgGradient={"linear-gradient(93.41deg, #E95B5B 0.6%, #C21818 100%)"} bgClip={"text"}
>{industry.solution.impact.carbon_footprint}<Box as="span" fontSize={"0.5em"}>MT</Box></Text>
            <Text fontSize={{lg:"0.6em",md:"0.55em",base:"0.4em"}}>Carbon Footprint Reduction</Text>
            </Box>
            <Box p={"5px"}><Image  src="https://bertlabs-website.s3.ap-south-1.amazonaws.com/impact/carbon_footprint.png"  height={{lg:"100px",md:"85px",base:"70px"}}/></Box>
          </Box>
        </Box>

        <Box mt={"36px"}>
          <Text fontSize={{ base: "20px", lg: "36px" }} fontWeight={"700"}>
            Business Transformation Goals
          </Text>

          <Businesstransform data={industry.btg} />
        </Box>
      </Box>

      <Box
        p={{ lg: "0px", base: "0px" }}
        fontSize={{ lg: "36px", base: "20px" }}
        mt={"36px"}
      >
        <Text fontWeight={"bold"} fontSize={{ lg: "40px", base: "27px" }}>
          Products
        </Text>

        <Box display={{ base: "none", lg: "block" }} mt={"25px"}>
          <Text
            display={"flex"}
            fontFamily={"Mulish"}
            fontWeight={"bold"}
            fontSize={{ base: "24px", lg: "32px" }}
          >
            Bert Maximus
                <span style={{ verticalAlign: "top", fontSize: "0.5em" }}>&#174;</span>
          </Text>
          <Box fontSize={"21px"}>
            <Devices />
          </Box>
          <Link to="/demo/sales"><Button
            colorScheme="#d63e3e"
            mr="2"
            className="button"
            marginLeft={{ lg: "47%", base: "32%" }}
            display={"flex"}
            justifyContent={"center"}
            alignContent={"center"}
            marginTop={"70px"}
          >
            Contact Sales
          </Button></Link>
          
        </Box>
        <Box display={{ base: "none", lg: "block" }}>
          <Bertqurios />
          <Bertnova />
          <Bertgeminos
            text={industry.bert_geminus.desc}
            image={industry.bert_geminus.img}
          />
          <Bertoptimus
            text={industry.bert_optimus.desc}
            image={industry.bert_optimus.img}
          />
        </Box>

        <Box
          boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
          mb={"25px"}
          display={{ base: "block", lg: "none" }}
          mt={"36px"}
          //
        >
          <CustomAccordion industry={industry} />
          <Button
            colorScheme="#d63e3e"
            mr={{ lg: "8", sm: "0" }}
            className="button"
            marginLeft={{ lg: "20px", sm: "0" }}
            alignContent={"center"}
            gap={"10px"}
            mt={"16px"}
            width={"100%"}
          >
            Explore out products
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Industries;
