import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Text,
  Heading,
  Divider,
  VStack,
  HStack,
  Link,
  Flex,
  Spinner,Button
} from '@chakra-ui/react';

import Leftsidebar from './Leftsidebar';
function Applicants() {
  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(true);


  const Apply=()=>{
    return (
        <Box p="4">
        <Heading as="h1" mb="4">Job Applications</Heading>
        {loading ? (
          <Spinner size="xl" />
        ) : (
          <VStack spacing="4" align="stretch">
            {applications.map(application => (
              <Box key={application._id} p="4" borderWidth="1px" borderRadius="lg" boxShadow="md" display={"flex"} flexDirection={"column"} gap={"10px"}>
                <Text><strong>Name:</strong> {application.firstName} {application.lastName}</Text>
                <Text><strong>Contact No:</strong> {application.contactNo}</Text>
                <Text><strong>Email:</strong> {application.emailId}</Text>
                <Text><strong>LinkedIn Profile:</strong> <Link href={application.linkedInProfile} target="_blank">{application.linkedInProfile}</Link></Text>
                <Text><strong>Position Applying For:</strong> {application.positionApplyingFor}</Text>
                <Text><strong>Total Years of Experience:</strong> {application.totalYearsOfExperience}</Text>
                <Text><strong>Relevant Years of Experience:</strong> {application.relevantYearsOfExperience}</Text>
                <Text><strong>Notice Period:</strong> {application.noticePeriod}</Text>
                <HStack>
                  <Button as="a" href={application.resumeUrl} target="_blank" colorScheme="teal" size="sm">View Resume</Button>
                  
                </HStack>
              </Box>
            ))}
          </VStack>
        )}
      </Box>
    )
}

  useEffect(() => {
    // Fetch data from the backend when the component mounts
    const fetchData = async () => {
      try {
        const response = await axios.get('https://bert-labs-website.onrender.com/apply');
        setApplications(response.data);
        setLoading(false)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <Flex>
        <Box width={"50%"}>
            <Leftsidebar/>
        </Box>
        <Box width={"50%"} ml={"-10%"} mb={"10px"}>
        <Apply/>
        </Box>

    </Flex>
          
         );
}



export default Applicants;
