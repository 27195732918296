import React, { useRef } from "react";
import {
  Box,
  ChakraProvider,
  Text,
  Image,
  Button,
  Flex,
} from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./SCPCard.css";

import aiStartup from "../../assets/awards/ai-startup.png";
import aim from "../../assets/awards/aim.png";
import dun from "../../assets/awards/dun.png";
import indiaAi from "../../assets/awards/india-ai.png";
import nasscom from "../../assets/awards/nasscom.png";
import resilience from "../../assets/awards/resilence.png";

import "./SCPCard.css";

const awardsData = [
  {
    image: dun,
    desc: "Recognized as a “Start-Up 50 Trailblazer” by Dun & Bradstreet, in AI/ML/IoT based solutions.",
  },
  {
    image: indiaAi,
    desc: "Recognized by Gov of India run Platform like India AI, NASSCOM for our Innovation",
  },
  {
    image: aim,
    desc: "Recognized as Top 20 hottest AI Startups in India 2022 by AI magazine",
  },
  {
    image: nasscom,
    desc: "Recognized as AI Gamechanger 2022 in the ESG category by NASSCOM",
  },
  {
    image: aiStartup,
    desc: "Top 3 AI startups making a positive impact on the environment in India",
  },
  {
    image: resilience,
    desc: "Semi-finalist for the Leading Cities Eastern QBE 2022 AcceliCITY Resilience Challenge",
  },
];

const AwardsCard = () => {
  const slider = useRef(null);

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    prevArrow: null, // Remove default previous arrow
    nextArrow: null, // Remove default next arrow
    responsive: [
      {
        breakpoint: 1370,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handlePrevClick = () => {
    slider.current.slickPrev();
  };

  const handleNextClick = () => {
    slider.current.slickNext();
  };

  return (
    <ChakraProvider>
      <Box position={"relative"} padding={{ lg: "3%", md: "7%", base: "5%" }}>
        <Text
          fontFamily={"Mulish"}
          fontWeight={"bold"}
          fontSize={{ base: "20px", lg: "36px" }}
        >
          Awards
        </Text>
        <Text fontWeight={"600"} fontSize={{lg:'20px',md:"18px",base:"16px"}}>
          Recognizing and celebrating our passion, innovation and problem
          solving
        </Text>
        <Box position={"absolute"} left={"-2%"} top={"50%"}>
          <Button onClick={handlePrevClick} variant="unstyled">
            <ChevronLeftIcon boxSize={8} color="black" />
          </Button>
        </Box>

        <Box overflow={"hidden"} align={'center'}>
          <Slider {...settings} ref={slider} className="slick-slider">
            {awardsData.map((item, index) => (
              <Box key={index} p="4">
                <Box
                  boxShadow="md"
                  borderRadius="xl"
                  overflow="hidden"
                  borderWidth="1px"
                  borderColor="gray.200"
                  width={{ lg: "300px", md: "90%", base: "100%" }}
                  height={{lg:"480px",md:"480px",base:"400px"}}
                  backgroundColor="#154554"
                >
                  <Image
                    src={item.image}
                    h={{lg:"300px",md:"300px",base:"250px"}}
                    width={"100%"}
                    bg="gray.100"
                  />
                  <Box
                    p="4"
                    // backgroundColor="#154554"
                    fontSize={{lg:"23px", md:"18px", base:"16px"}}
                    fontWeight="400"
                    lineHeight="29.05px"
                  >
                    <Text color="#FCF7FF">{item.desc}</Text>
                  </Box>
                </Box>
              </Box>
            ))}
          </Slider>
        </Box>
        <Box position={"absolute"} right={"-2%"} top={"50%"}>
          <Button onClick={handleNextClick} variant="unstyled">
            <ChevronRightIcon boxSize={8} color="black" />
          </Button>
        </Box>
      </Box>
    </ChakraProvider>
  );
};

export default AwardsCard;
