import React, { useState } from 'react';
import {
  Box,
  Text,
  Heading,
  Image,
  Stack,
  Button,
  useColorModeValue,
} from '@chakra-ui/react';

const teamMembers = [
  {
    name: 'Rohit Kochar',
    title: 'Founder, Executive Chairman and CEO',
    imageUrl: 'https://bertlabs-website.s3.ap-south-1.amazonaws.com/rohitceo.png',
    text: `Rohit has over 28 years of experience in building high-growth, highly profitable, high-valuation global technology companies. He has successfully led two companies to investor exits, achieving valuations of US$1.5 billion and US$1.8 billion, respectively.
    In 2017, Rohit founded Bert Labs with a vision to establish a globally admired institution and technology conglomerate leader from India. His goal is to address complex client problems through innovation and create exponential value for stakeholders.
    Rohit's vision for Bert Labs is to build a lasting global technology conglomerate that solves real-world problems by harnessing human innovation, making the world more sustainable. He passionately leads research, innovation, and development of various platforms and products at Bert Labs, guiding the company's team of researchers and scientists. Rohit also oversees the product roadmap for future innovations and client programs across different regions. His leadership focuses on developing the company’s go-to-market strategy, client relationships, and deployment sign-offs. Additionally, he emphasizes integrity, ethics, and corporate governance.
    Before founding Bert Labs, Rohit had a distinguished career. He was one of the youngest professional CEOs in India and served as Senior Advisor and CEO-in-Residence for several U.S.-based private equity investment firms, including Veronis Suhler Stevenson, The Gores Group, H.I.G Capital, The Jordan Company, TPG Capital, Partners Group, and Genstar Capital. In these roles, he evaluated technology companies globally for acquisition and development.
    As President and Board Member of HOV Services (an Apollo Global Management investee company), Rohit led the company's growth from $40 million to $600 million in revenue, with an EBITDA of $150 million. The company was valued at $1.8 billion at the time of Apollo's exit to Morgan Stanley PE. As CEO and Board Member of Macmillan Publishing Solutions Public Limited, he increased the company's revenue from $15 million to $50 million within a year, achieving an EBITDA margin of 32%. As Managing Director at RR Donnelley, he integrated Hays Plc's technology business with Office Tigers, managing a combined revenue base of $630 million and an operating profit of $130 million. At Hays Plc, he grew the technology business from $20 million to $450 million, achieving a $1.5 billion valuation at the time of sale to RR Donnelley.
    Earlier in his career, Rohit served as Vice President at GE Capital International Services, where he built two Centers of Excellence from scratch to $200 million in revenue with a 30% operating profit margin. He also served as Vice President at American Express Bank, quickly rising to the position within three years and overseeing the personal financial services division in India.
    Rohit is a recognized thought leader on the transformative impact of AI and has been featured in leading industry publications.`
  },
  {
    name: 'Bhavana Mittal',
    title: 'Co-Founder, Chief Growth Officer and Executive Director',
    imageUrl: 'https://bertlabs-website.s3.ap-south-1.amazonaws.com/bhavnacto.png',
    text: `Bhavana, an award-winning marketing, media, and digital professional, has over 25 years of experience and serves as a speaker, advisory board member, and jury member for various industry associations. She is also a certified yoga teacher (YT200).
    At Bert Labs, Bhavana plays a multifaceted and pivotal role. She is a visionary leader, setting the strategic direction of the company and ensuring its mission aligns with the fast-evolving tech landscape. She provides overall guidance, supervises the executive team, and maintains a strong connection with the board of directors to garner support for innovative ventures.
    Bhavana drives growth in Bert Labs' dynamic environment by identifying opportunities, forging strategic partnerships, and driving revenue through the introduction of Bert Platform Solutions and products. She closely monitors market trends, leverages data analytics to inform product development and marketing strategies, and fosters customer engagement to ensure the company's offerings meet emerging needs. Her leadership balances visionary direction with actionable growth initiatives, propelling Bert Labs to success in the competitive tech landscape.
    Bhavana's career includes:
    VP (Head) – Media and Digital at RPSG Group: Worked across all group businesses, including Saregama Caravan, Too Yumm!, Naturali, Spencer’s, Nature’s Basket, Fortune India, Open, Hello! Magazine, and RPSG Sports (including Lucknow SuperGiants).
    Head – Media, Digital, and Communication, South Asia at Reckitt Benckiser: Managed digital and media for all brands, including Dettol, Durex, Harpic, Lizol, Vanish, and Veet. Part of the integration team for Mead Johnson into RB and served as India representative for the Digital CoE.
    Head – Media and CSR, India Subcontinent for GSK Consumer Healthcare: Oversaw all brands, including Horlicks, Boost, Eno, Crocin, and Iodex. Successfully launched Sensodyne in India, led indirect procurement, and was part of the integration team for Novartis into GSKCH. Led a global task force for strategic initiatives.
    Manager and Head, Media Audit at Accenture Consulting: Established the media audit practice for Indian and international clients.
    Media Director/Head of Media at Cheil Communications: Managed Samsung and Hyundai business, launching Samsung mobile phones, LEDs, side-by-side refrigerators, and Hyundai Tuscon and Sonata.
    Media Director at Initiative Media: Oversaw media for LG, Revlon, and Nestle.
    Manager at Maximize (GroupM): Handled media for NIIT, Electrolux, and JK Tyres.
    Media Supervisor at Universal McCann: Managed media for Reckitt Benckiser and Indiatimes.
    Media Executive at Saatchi & Saatchi: Launched Hyundai Santro and Accent in India.`
  }
];


const Team = () => {
  return (
    <>
    <Box  padding="15px"  fontFamily={"Mulish"}>
      <Heading fontSize={"27px"} fontFamily={"Mulish"}>Leadership Team</Heading>
    </Box>
    <Box
      display="grid"
      gridTemplateColumns={{ base: "repeat(1, 1fr)", lg: "repeat(2, 1fr)" }}
      justifyContent="center"
      gap="25px"
      mb="70px"
      padding="15px"
      fontFamily={"Mulish"}
    >
      {teamMembers.map((member) => (
        <TeamMember key={member.name} {...member} />
      ))}
    </Box>
    </>
  );
};

const TeamMember = ({ name, title, imageUrl, text }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpansion = () => setIsExpanded(!isExpanded);

  return (
    <Box
  p={6}
  display="flex"
  flexDirection="column"  // Ensure image is at the top
  alignItems="flex-start" // Align items (including image) at the top
  rounded="lg"
  overflow="hidden"
  boxShadow="lg"
  bg={useColorModeValue('white', 'gray.800')}
  _hover={{ boxShadow: "xl" }}
  transition="all 0.3s ease-in-out"
  fontFamily="Mulish"
>
  <Image
    width={{ base: "100%", md: "40%" }}
    src={imageUrl}
    alt={name}
    mx="auto"
    borderRadius="8px"
    objectFit="cover"
  />
  <Box
    width="100%"
    pl={{ md: 6 }}
    fontFamily="Mulish"
    position="relative"
  >
    <Stack textAlign={{ base: "center", md: "left" }} ml="10px">
      <Heading as="h3" fontSize="xl" fontWeight="bold" color="#D63E3E" fontFamily="Mulish">
        {name}
      </Heading>
      <Text fontSize="14px" color="gray.500" mt="-4px" fontFamily="Mulish">
        {title}
      </Text>

      <Text fontSize="sm" color="gray.700" mt="10px" fontFamily="Mulish">
        {isExpanded ? text : `${text.substring(0, 700)}...`}
        <Button onClick={toggleExpansion} variant="link" color="#D63E3E">
          {isExpanded ? 'View Less' : 'View More'}
        </Button>
      </Text>
    </Stack>
    <Box
      backgroundColor="#D63E3E"
      position="absolute"
      borderRadiusTop="50%"
      width="2px"
      height="50px"
      left="20px"
      top="0px"
      border="2px solid red"
      display={{ base: "none", lg: "block" }}
    />
  </Box>
</Box>

  );
};

export default Team;
