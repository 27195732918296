// src/components/BlogCard.js
import { Box, Image, Text, VStack,Button, SimpleGrid } from '@chakra-ui/react';
import { useState } from 'react';
import { Link as RouterLink } from "react-router-dom";
import { Link as ChakraLink } from "@chakra-ui/react";

const BlogCard = ({ post }) => {
  return (
    <Box borderWidth="1px" borderRadius="lg" overflow="hidden" m={2}>
      <Image src={post.image} width={"100%"} alt={post.title} />

      <VStack p="6" align="start">
        <Box display={"flex"} justifyContent={"space-around"} gap={"100px"} fontFamily={"Mulish"}>
          <Text fontSize="sm" color="gray.500">
            {post.category}
          </Text>
          <Text fontSize="sm" color="gray.500" >
          • {post.date}
          </Text>
        </Box>
        <Text mt="1" fontWeight="semibold" as="h4" lineHeight="tight">
          {post.title}
        </Text>
        <ChakraLink as={RouterLink} to={`/blogs/${post.id}`} color="teal.500">
    Read More
  </ChakraLink>
      </VStack>
    </Box>
  );
};






const Blogs = () => {
  const [viewMore, setViewMore] = useState(false);

  const blogPosts = [
    {
      id: 1,
      title: 'The Future of Energy Optimization with Our AI-Powered Dashboard',
      category: 'UI Dashboard',
      date: 'May 24, 2022',
      image: 'https://bertlabs-website.s3.ap-south-1.amazonaws.com/Rectangle+2.png',
      link: '#',
    },
   
   
  ];

  const displayedPosts = viewMore ? blogPosts : blogPosts.slice(0, 6);

  const toggleViewMore = () => {
    setViewMore((prev) => !prev);
  };

  return (
    <Box maxW="1200px" mx="auto" p={5}>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={5}>
        {displayedPosts.map((post) => (
          <BlogCard key={post.id} post={post} />
        ))}
      </SimpleGrid>
      <Box textAlign="center" mt={5}>
        <Button onClick={toggleViewMore}>
          {viewMore ? 'View Less' : 'View More'}
        </Button>
      </Box>
    </Box>
  );
};

export default Blogs;
