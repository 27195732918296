import React from "react";
import { Box, Image, Button, Text, Divider, Link } from "@chakra-ui/react";
import { useState, useEffect } from "react";
// import newsimage from "../assets/Resources/news-all.png"
import ImageSlider from "../Components/Slider/Imageslider";
import iconpic from "../assets/Contact-us/Frame.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import Blogs from "./Blogs";

const Resources = () => {
  const [type, setType] = useState("awards");
  const [viewmore, setViewmore] = useState(4);
  const [resourcedata, setResourcedata] = useState([{
    _id: '66335b5e57173acd9cd52cc6',
    text: 'Start-Up 50 Trailblazer 2023” by Dun & Bradstreet, in AI/ML/IoT based solutions.',
    category: 'Awards',
    imageUrl: 'https://bertlabs-website.s3.ap-south-1.amazonaws.com/media/awards-cards+1/awards-cards/awards-card1.svg',
    link: 'https://www.linkedin.com/posts/bert-labs_congratulations-bertlabs-startup-activity-6951938014766495744-PI9L/',
  },
  {
    _id: '66335b5e57173acd9cd52cc7',
    text: 'NASSCOM AI Gamechanger 2022 in ESG Category',
    category: 'Awards',
    imageUrl: 'https://bertlabs-website.s3.ap-south-1.amazonaws.com/media/awards-cards+1/awards-cards/awards-card5.svg',
    link: 'https://bertlabs.sharepoint.com/:p:/s/BertComms/EbZ-5aDPO59PlbD9LN3ccGsBDfPHrF2uLQJlgsB7UW04D3B&wdLOR=c57AE8EED-DF99-4F2D-94BD-65442AA0AEBE',
  },
  {
    _id: '66335b5e57173acd9cd52cc8',
    text: 'Bert Labs™ leverages AI and IoT for energy efficiency solutions through customized applications',
    category: 'Awards',
    imageUrl: 'https://bertlabs-website.s3.ap-south-1.amazonaws.com/media/awards-cards+1/awards-cards/awards-card9.svg',
    link: 'https://www.linkedin.com/feed/update/urn:li:activity:7110502523936866304',
  },
  {
    _id: '66335b5e57173acd9cd52cc9',
    text: 'Top 20 hottest AI Startups in India 2022” by AI magazine',
    category: 'Awards',
    imageUrl: 'https://bertlabs-website.s3.ap-south-1.amazonaws.com/media/awards-cards+1/awards-cards/awards-card3.svg',
    link: 'https://analyticsindiamag.com/20-hottest-ai-startups-in-india-2022/',
  },
  {
    _id: '66335b5e57173acd9cd52cca',
    text: 'Start-Up 50 Trailblazer 2023” by Dun & Bradstreet, in AI/ML/IoT based solutions.',
    category: 'Awards',
    imageUrl: 'https://bertlabs-website.s3.ap-south-1.amazonaws.com/media/awards-cards+1/awards-cards/awards-card4.svg',
    link: 'https://nasscom.in/sme-inspire-awards/',
  },
  {
    _id: '66335b5e57173acd9cd52ccb',
    text: 'NASSCOM AI Gamechanger 2022 in ESG Category',
    category: 'Awards',
    imageUrl: 'https://bertlabs-website.s3.ap-south-1.amazonaws.com/media/awards-cards+1/awards-cards/awards-card2.svg',
    link: 'https://indiaai.s3.ap-south-1.amazonaws.com/docs/ai-gamechangers-22-06-2022.pdf',
  },
  {
    _id: "663360f27faf5870a21d0e40",
    category: "Bert Labs IPR",
    imageUrl: "https://bertlabs-website.s3.ap-south-1.amazonaws.com/media/ipr/materialmanufacturing.png",
    paragraph: "Bert Platform Solution™ for optimizing Fuel and Energy Consumption for a Kiln by sensing, analysing and controlling equipment in real time by using Physics and Machine Learning based models",
    text: "Material Manufacturing - Fuel and Energy Optimization for a Kiln",
  }
  ,
  {
    _id: "66336b6b7c477e1cc4ead6a8",
    category: "Media and Press",
    imageUrl: "https://bertlabs-website.s3.ap-south-1.amazonaws.com/media/news-cards/nasscom.png",
    imagetwoUrl: "https://bertlabs-website.s3.ap-south-1.amazonaws.com/media/news-cards/nasscom-logo.jpg",
    link: "https://community.nasscom.in/communities/nasscom-insights/aiops-key-achieving-tech-agility",
    text: "Building Sustainability & Energy Efficiency With AI & IOT",
  }
  ]);

  
  console.log(type);
  const navigate = useNavigate();

  const getResources = async () => {
    try {
      let data = await axios.get("https://bert-labs-website.onrender.com/admin/resources");
      console.log(data.data);
      sessionStorage.setItem("resourcedata", JSON.stringify(data.data));
      setResourcedata(data.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const storedData = JSON.parse(sessionStorage.getItem("resourcedata"));
    if (storedData) {
      setResourcedata(storedData);
    } else {
      getResources();
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  return (
    <Box fontFamily="Mulish">
      <Box margin={"auto"}>
        <Box margin={"auto"}>
          <Box paddingX={{ lg: "64px", base: "16px" }} align={"center"}>
            <ImageSlider />
          </Box>
          <Box>
            <Box paddingX={{ lg: "64px", base: "16px" }}>
              
           
          <Text
              alignText="center"
              fontSize={{ lg: "36px", base: "17px" }}
              color={"gray"}
              fontWeight={"bold"}
              mt={{ lg: "64px", base: "34px" }}
            >
              Latest from the Blogs
            </Text>

          
          <Blogs resourcedata={resourcedata}/>


            </Box>
          </Box>
          <Box paddingX={{ lg: "64px", base: "16px" }}>
            <Text
              alignText="center"
              fontSize={{ lg: "36px", base: "17px" }}
              color={"gray"}
              fontWeight={"bold"}
              mt={{ lg: "64px", base: "34px" }}
            >
              Categories
            </Text>
            <Box
              display={"flex"}
              flexWrap={"wrap"}
              gap={{ lg: "25px", base: "10px" }}
              mt={"20px"}
            >
              {[...new Set(resourcedata.map((elem) => elem.category))].map(
                (category, index) => (
                  <Button
                    key={index}
                    borderRadius="50px"
                    padding={{ lg: "2px 15px", base: "1px 7px" }}
                    display="inline-flex"
                    alignItems="center"
                    justifyContent="center"
                    _hover={{ bg: "lightcoral" }}
                    _active={{bg: "lightcoral"}}
                    onClick={() => setType(category)}
                    background={type===category?"#D63E3E":"inherit"}
                    boxShadow={"md"}
                    color={type===category?"white":"inherit"}
                    
                  >
                    {category}
                  </Button>
                )
              )}
            </Box>

            <Box
              display={"grid"}
              gridTemplateColumns={{
                xl: "repeat(4,1fr)",
                lg: "repeat(3,1fr)",
                md: "repeat(2,1fr)",
                sm: "repeat(1,1fr)",
              }}
              mt={"25px"}
              gap={{ lg: "25px", base: "10px" }}
            >
              {resourcedata
                .filter((elem) => {
                  if (type === "all") {
                    return true;
                  } else {
                    return elem.category.toLowerCase() === type.toLowerCase();
                  }
                })
                .slice(0, viewmore) // Adjust this line to match your actual data structure
                .map((elem, index) => (
                  <Box
                    key={index}
                    borderRadius="10px"
                    boxShadow="rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px"
                    padding="10px"
                    // onClick={(event) => {
                    //   window.location.href = elem.link;
                    // }}
                    position={"relative"}
                  >
                    <Box width="100%" height={"270px"}>
                      <Image src={elem.imageUrl} width="100%" height={"100%"} />
                    </Box>
                    {elem.date ? (
                        <Box display="flex" justifyContent="end" mt={"20px"}>
                          {new Date(elem.date).toLocaleDateString("en-US", {
                            month: "long",
                            day: "numeric",
                            year: "numeric",
                          })}
                        </Box>
                      ) : null}
                    {elem.imagetwoUrl ? (
                      <Box mt={"5px"} maxWidth={"266px"} height={"auto"}>
                        <Image
                          src={elem.imagetwoUrl}
                          // width="100%"
                          // height={"100%"}
                          maxWidth={"266px"} height={"50px"}
                        />
                      </Box>
                    ) : null}

                    <Box height="50%" mt="5px" padding="10px">
                      <Divider />
                      <Text mt="15px" fontSize="17px" lineHeight="25px">
                        {elem.text}
                      </Text>
                      <Text
                        mt="10px"
                        fontSize="14px"
                        lineHeight="21px"
                        color={"gray"}
                      >
                        {elem.paragraph}
                      </Text>

                      <Box position={"absolute"} bottom={"3px"} right={"10px"}>{elem.link ? (
                        <Link display="flex" justifyContent="end" mt={"20px"} color={"#0d6efd"} target="_blank" href={elem.link}>
                          view more...
                        </Link>
                      ) : null}</Box>

                    </Box>
                  </Box>
                ))}
            </Box>
            <Box mt={"17px"}>
              {viewmore > 4 ? (
                <Button
                  colorScheme="red" // Use a valid color scheme name like "red"
                  mr={{ lg: "8", sm: "0" }}
                  className="button"
                  marginLeft={{ lg: "20px", sm: "0" }}
                  alignContent="center"
                  gap="10px"
                  onClick={() => {
                    setViewmore(4);
                  }}
                >
                  View Less
                </Button>
              ) : (
                <Button
                  colorScheme="red" // Use a valid color scheme name like "red"
                  mr={{ lg: "8", sm: "0" }}
                  className="button"
                  marginLeft={{ lg: "20px", sm: "0" }}
                  alignContent="center"
                  gap="10px"
                  onClick={() => {
                    setViewmore(resourcedata.length);
                  }}
                >
                  View More
                </Button>
              )}
            </Box>
          </Box>
          <Box width={"100%"} height={{ xl: "auto", lg: "500px" }}>
            <Box
              bgGradient="linear(to-r, #010C1E, blue.500)"
              w="100%"
              height={"auto"}
              p={4}
              display={"flex"}
              // height={{ base: "296px", lg: "384px" }}
              mt={"34px"}
            >
              <Box
                width={{ base: "52%", lg: "60%" }}
                padding={{ lg: "72px", md: "60px", base: "24px" }}
              >
                <Box
                  fontWeight="bold"
                  fontSize={{ base: "16px", lg: "48px" }}
                  color={"white"}
                >
                  Working on a Bert Labs<span style={{ verticalAlign: "top", fontSize: "0.5em" }}>TM</span> story? If you have{" "}
                  <Box as="span" color="red">
                    questions
                  </Box>
                  , we’ve got answers.
                </Box>
                <HashLink smooth to="/#get-in-touch">
                  <Button
                    colorScheme="#d63e3e"
                    mr="2"
                    className="button"
                    mt={"20px"}
                  >
                    Get in Touch
                  </Button>
                </HashLink>{" "}
              </Box>
              <Box
                width={{ lg: "40%", base: "48%" }}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Image
                  src={iconpic}
                  height={{ xl: "80%", lg: "70%", md: "70%", base: "64%" }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Resources;
