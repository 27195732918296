// src/components/BlogPost.js
import { Box, Image, Text, VStack, Heading,Stack, Divider} from '@chakra-ui/react';
import React from 'react';
import { useParams } from 'react-router-dom';
const blogPosts = [
    {
      id: 1,
      title: 'The Future of Energy Optimization with Our AI-Powered Dashboard',
      category: 'UI Dashboard',
      date: 'May 24, 2022',
      image: 'path_to_image1.jpg',
      link: '#',
    },
    {
      id: 2,
      title: 'Designers are meant to be loved, not to be understood.',
      category: 'UI Development',
      date: 'May 24, 2022',
      image: 'path_to_image2.jpg',
      link: '#',
    },
    {
      id: 3,
      title: 'Designers are meant to be loved, not to be understood.',
      category: 'UI Dashboard',
      date: 'May 24, 2022',
      image: 'path_to_image3.jpg',
      link: '#',
    },
    {
      id: 4,
      title: 'The Future of Energy Optimization with Our AI-Powered Dashboard',
      category: 'UI Dashboard',
      date: 'May 24, 2022',
      image: 'path_to_image1.jpg',
      link: '#',
    },
    {
      id: 5,
      title: 'Designers are meant to be loved, not to be understood.',
      category: 'UI Development',
      date: 'May 24, 2022',
      image: 'path_to_image2.jpg',
      link: '#',
    },
    {
      id: 6,
      title: 'Designers are meant to be loved, not to be understood.',
      category: 'UI Dashboard',
      date: 'May 24, 2022',
      image: 'path_to_image3.jpg',
      link: '#',
    },
  ];

  const Section = ({ title, content,image }) => (
    <Box mb={7} fontFamily={"Mulish"}>
      <Heading as="h2" size="lg" mb={2} fontFamily={"Mulish"}>{title}</Heading>
      
      {
        image ? <Box display={"flex"} gap={"40px"}>
<Box width={"60%"}>
<Text fontWeight={"bold"}>{content}</Text>
</Box>

<Box width={"200px"} height={"200px"}>
<Image src={image} alt="image" />
</Box>

        </Box>  : <Text fontWeight={"bold"}>{content}</Text>

      }
    </Box>
  );

const BlogPost = () => {
  const { id } = useParams();
  const post = blogPosts.find(p => p.id === parseInt(id));
  
  return (
    <Box  p={5}>
     
      <VStack p="6" align="start">
        <Box bg={"#5C4EC9"} width={"100%"} height={"270px"} padding={"70px"} display={"flex"} justifyContent={"space-between"} fontFamily={"Mulish"}>
            <Box width={"50%"}>
            <Heading as="h1" color={"white"} fontFamily={"Mulish"} >{post.title}</Heading>
            </Box>
            <Box height={"270px"} width={"270px"}>
                <Image src="https://bertlabs-website.s3.ap-south-1.amazonaws.com/Dashboard+(1).svg"/>
            </Box>
        
        </Box>
  
      
        <Text mt="1">{post.content}</Text>
        <Box p={6}>
            <Box py={"27px"} fontWeight={"bold"} fontFamily={"Mulish"}>
            In the dynamic sectors of modern manufacturing and smart building management, energy optimization is of utmost importance. At Bert Labs, we are thrilled to unveil our groundbreaking AI solution designed to revolutionize energy management: The Energy Optimization Dashboard. Leveraging the power of Reinforcement Learning, this state-of-the-art tool not only enhances efficiency but also prioritizes user experience, making it a game-changer for various industries.
            </Box>
    
      <Stack spacing={8}>
        <Section
          title="Real-Time Smart Monitoring and Actuation"
          content="Our smart monitoring dashboard empowers our solution when devices are synchronized in real-time. This feature ensures that real-time and automated operations are triggered when needed. This automation not only enhances performance but also enables significant energy savings. Our AI dashboard constantly checks the device’s operations, making the necessary commands during peak times to save on operational costs. Our clients are also able to optimize both energy usage and comfort levels, ensuring an ideal balance."
          image="https://bertlabs-website.s3.ap-south-1.amazonaws.com/30576710_7706579+1.png"
        />
        <Section
          title="An Intuitive, Engaging User Experience"
          content="One of the standout features of our dashboard is its intuitive and engaging design. The user interface has been designed to have easy navigation for plants, where the operation’s layout and navigation options are installed. This realistic design gives a simulated feel that makes the operator job easy for navigation and use. In addition, we enhance the experience by adding visuals through the usage of actual images of the plant location. The visuals are customized with the theme colors of our client to bring out a more customized and personalized experience."
        />
        <Section
          title="Personalization for Client Requirement"
          content="Understanding that every client has unique needs, our dashboard is fully customizable. From color themes to incorporating images of the plant, our dashboard is tailored to reflect the client’s brand and operational environment. This level of personalization ensures that clients feel connected to the system, fostering trust and satisfaction."

        />
        <Section
          title="Enhancing Safety and Operational Efficiency"
          content="Beyond optimization and aesthetics, our dashboard significantly improves the safety and efficiency of plant operations. By allowing operators to monitor and control devices remotely, it eliminates the need for risky visits to critical areas of the plant. Whether dealing with high temperatures, pressure zones, high humidity, or other operational hazards, our dashboard provides important data in real time. This data not only enhances safety but also boosts operational efficiency by minimizing downtime and unplanned maintenance."
        />
        <Section
          title="Driving Cost Efficiency and Sustainability"
          content="Smart optimization leads to significant cost savings, a crucial advantage for any business. Our AI-powered dashboard continuously analyzes the plant’s operations to identify opportunities for energy savings, ensuring that resources are used as efficiently as possible. This proactive approach reduces energy costs and contributes to sustainability goals."
        />
        <Section
          title="Solution for Today’s Business Needs"
          content="Our solution directly addresses the current challenges faced by our clients. By providing an intuitive, engaging, and user-friendly interface, we offer a tool that simplifies energy management, enhances safety, and drives significant cost efficiencies."
        />
      </Stack>
      <Divider my={8} />
      <Box textAlign="center" fontFamily={"Mulish"}>
        <Text fontSize="lg" fontWeight="bold">At Bert Labs, we are committed to innovation and excellence. Our Energy Optimization Dashboard is a testament to this commitment, offering a robust solution that combines advanced AI technology with a user-centric design. We invite you to explore how this dashboard can transform your energy management practices, delivering tangible benefits in efficiency, safety, and cost savings. Together, let’s pave the way for a smarter, more sustainable future.</Text>
      </Box>
    </Box>
      </VStack>
    </Box>
  );
};

export default BlogPost;
