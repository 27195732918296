import React from 'react';
import { Box, Button, FormControl, FormLabel, Input, Textarea, VStack } from '@chakra-ui/react';
import Select from 'react-select';
import { useState } from 'react';
import axios from 'axios';
import Leftsidebar from './Leftsidebar';

const Jobinput = () => {
  const skillOptions = [
    { value: 'JavaScript', label: 'JavaScript' },
    { value: 'Python', label: 'Python' },
    { value: 'React', label: 'React' },
    { value: 'Kubernetes', label: 'Kubernetes' },
    { value: 'Docker', label: 'Docker' },
    { value: 'Node.js', label: 'Node.js' },
    { value: 'Angular', label: 'Angular' },
    { value: 'Vue.js', label: 'Vue.js' },
    { value: 'HTML', label: 'HTML' },
    { value: 'CSS', label: 'CSS' },
    { value: 'Java', label: 'Java' },
    { value: 'C#', label: 'C#' },
    { value: 'PHP', label: 'PHP' },
    { value: 'Ruby', label: 'Ruby' },
    { value: 'Swift', label: 'Swift' },
    { value: 'Go', label: 'Go' },
    { value: 'SQL', label: 'SQL' },
    { value: 'MongoDB', label: 'MongoDB' },
    { value: 'PostgreSQL', label: 'PostgreSQL' },
    { value: 'MySQL', label: 'MySQL' },
    { value: 'AWS', label: 'AWS' },
    { value: 'Azure', label: 'Azure' },
    { value: 'Google Cloud', label: 'Google Cloud' },
    { value: 'CI/CD', label: 'CI/CD' },
    { value: 'Git', label: 'Git' },
    { value: 'RESTful APIs', label: 'RESTful APIs' },
    { value: 'GraphQL', label: 'GraphQL' },
    { value: 'Redux', label: 'Redux' },
    { value: 'TypeScript', label: 'TypeScript' },
    { value: 'SASS', label: 'SASS' },
    { value: 'LESS', label: 'LESS' },
    { value: 'Bootstrap', label: 'Bootstrap' },
    { value: 'Tailwind CSS', label: 'Tailwind CSS' },
    { value: 'Computer Vision', label: 'Computer Vision' },
    { value: 'Machine Learning', label: 'Machine Learning' },
    { value: 'Keras', label: 'Keras' },
    { value: 'Scikit-learn', label: 'Scikit-learn' },
    { value: 'TensorFlow', label: 'TensorFlow' },
  ];

  const initialFormData = {
    title: '',
    department: '',
    summary: 'Summary',
    noticePeriod: '',
    responsibilities: '',
    requirements: 'requirements',
    additionalInfo: '',
    skills: [],
    experience: '',
    location: '',
    employmentType: '',
  };

  const [formData, setFormData] = useState({ ...initialFormData });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios.post('https://bert-labs-website.onrender.com/admin/job', formData)
      .then((res) => {
        console.log(res);
        // Clear form data after successful submission
        setFormData({ ...initialFormData });
      })
      .catch((error) => {
        console.error('Error submitting form:', error);
      });
  };

  return (
    <>
      <Box display={'flex'} mb={'10px'}>
        <Box width={'50%'}>
          <Leftsidebar />
        </Box>

        <Box width={'50%'} ml={'-10%'}>
          <form onSubmit={handleSubmit}>
            <VStack spacing={4} align="stretch">
              <FormControl id="title">
                <FormLabel>Title:</FormLabel>
                <Input type="text" name="title" value={formData.title} onChange={handleChange} />
              </FormControl>

              <FormControl id="department">
                <FormLabel>Department:</FormLabel>
                <Input type="text" name="department" value={formData.department} onChange={handleChange} />
              </FormControl>

              <FormControl id="noticePeriod">
                <FormLabel>Notice Period:</FormLabel>
                <Input type="text" name="noticePeriod" value={formData.noticePeriod} onChange={handleChange} />
              </FormControl>

              <FormControl id="responsibilities">
                <FormLabel>Roles and Responsibilities:</FormLabel>
                <Textarea name="responsibilities" value={formData.responsibilities} onChange={handleChange} />
              </FormControl>

              <FormControl id="additionalInfo">
                <FormLabel>Additional Info:</FormLabel>
                <Textarea name="additionalInfo" value={formData.additionalInfo} onChange={handleChange} />
              </FormControl>

              <FormControl id="skills">
                <FormLabel>Skills:</FormLabel>
                <Select
                  name="skills"
                  options={skillOptions}
                  value={skillOptions.filter((option) => formData.skills.includes(option.value))}
                  onChange={(selectedOptions) =>
                    handleChange({
                      target: { name: 'skills', value: selectedOptions.map((option) => option.value) },
                    })
                  }
                  isMulti
                />
              </FormControl>

              <FormControl id="experience">
                <FormLabel>Experience:</FormLabel>
                <Input type="text" name="experience" value={formData.experience} onChange={handleChange} />
              </FormControl>

              <FormControl id="location">
                <FormLabel>Location:</FormLabel>
                <Input type="text" name="location" value={formData.location} onChange={handleChange} />
              </FormControl>

              <FormControl id="employmentType">
                <FormLabel>Employment Type:</FormLabel>
                <Input type="text" name="employmentType" value={formData.employmentType} onChange={handleChange} />
              </FormControl>

              <Button type="submit" colorScheme="blue">
                Submit
              </Button>
            </VStack>
          </form>
        </Box>
      </Box>
    </>
  );
};

export default Jobinput;
