import React, { useEffect, useState } from 'react';
import {
  FormControl,
  FormLabel,
  Input,
  Select,
  Textarea,
  Button,
  Box,
  Text,
  Heading,
  Spinner,
  Alert,
  AlertIcon
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

function Apply() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    contactNo: '',
    emailId: '',
    linkedInProfile: '',
    positionApplyingFor: '',
    totalYearsOfExperience: '',
    relevantYearsOfExperience: '',
    noticePeriod: '',
    resume: null,
  });
  const [jobdata, setJobdata] = useState([]);
  const [loading, setLoading] = useState(true);
  const [submitted, setSubmitted] = useState(false); // State to track form submission

  const handleChange = (event) => {
    const { name, value, files } = event.target;

    if (name === 'resume') {
      setFormData({ ...formData, [name]: files[0] });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      console.log('Form Data:', formData);
      // Assuming your axios POST request here
      await axios.post("https://bert-labs-website.onrender.com/apply", formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      console.log("Form submitted successfully");
      setSubmitted(true);
      setFormData({
        firstName: '',
        lastName: '',
        contactNo: '',
        emailId: '',
        linkedInProfile: '',
        positionApplyingFor: '',
        totalYearsOfExperience: '',
        relevantYearsOfExperience: '',
        noticePeriod: '',
        resume: null,
      });
    } catch (error) {
      console.error('Error submitting form:', error);
      // Handle error state or display error message
    }
  };

  const params = useParams();
  console.log(params);

  const getData = async () => {
    try {
      const storedJobdata = localStorage.getItem("jobdata");
      if (storedJobdata) {
        // If job data exists, set the jobdata state from localStorage
        setJobdata(JSON.parse(storedJobdata));
        setLoading(false);
      } else {
        // If job data doesn't exist in localStorage, fetch it from the API endpoint
        const response = await axios.get(`https://bert-labs-website.onrender.com/admin/job/${params.id}`);
        // Set the jobdata state
        setJobdata(response.data);
        setLoading(false);
        // Store job data in localStorage
        localStorage.setItem("jobdata", JSON.stringify(response.data));
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  console.log("jobdata", jobdata);

  if (loading) {
    return (
      <Box marginLeft={"40%"} marginTop={"25%"} mb={"100vh"}>
        <Spinner size="xl" color="blue.500" />;
      </Box>
    );
  }

  return (
    <Box padding={"20px"} width={{ base: "50%", md: "70%", base: "90%" }} mx="auto" mt={4} alignContent={"center"}>
      {submitted ? (
        <Box borderRadius="lg" p="4" mt="20" >
          <Alert status="success">
            <AlertIcon />
            Thanks for applying! We will get back to you
          </Alert>
        </Box>
      ) : (
        <Box borderRadius="lg" p="4" m="2" border={"10px solid #D63E3E"}>
          <Heading as="h2" size="lg" mb="2">Job Description</Heading>
          <Text><strong>Title:</strong> {jobdata.title}</Text>
          <Text><strong>Department:</strong> {jobdata.department}</Text>
          <Text><strong>Location:</strong> {jobdata.location}</Text>
          <Text><strong>Employment Type:</strong> Work from Office</Text>
          <Text><strong>Experience:</strong> {jobdata.experience}</Text>
          <Text><strong>Skills:</strong> {jobdata.skills.join(', ')}</Text>
          {/* <Text><strong>Summary:</strong> {jobdata.summary}</Text> */}

          <Text><strong>Notice Period:</strong> {jobdata.noticePeriod}</Text>

          <Text>
            <strong>Responsibilities:</strong>{" "}
            {jobdata.responsibilities.split(". ").map((responsibility, index) => (
              <React.Fragment key={index}>
                {index + 1}. {responsibility}.
                <br />
              </React.Fragment>
            ))}
          </Text>
        </Box>
      )}

      {!submitted && (
        <form onSubmit={handleSubmit}>
          <FormControl isRequired>
            <FormLabel htmlFor="firstName">First Name</FormLabel>
            <Input
              id="firstName"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              placeholder="Enter your first name"
            />
          </FormControl>
          <FormControl isRequired >
            <FormLabel htmlFor="lastName">Last Name</FormLabel>
            <Input
              id="lastName"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              placeholder="Enter your last name"
            />
          </FormControl>
          <FormControl isRequired >
            <FormLabel htmlFor="contactNo">Contact No</FormLabel>
            <Input
              id="contactNo"
              name="contactNo"
              value={formData.contactNo}
              onChange={handleChange}
              placeholder="Enter your contact number"
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel htmlFor="emailId">Email ID</FormLabel>
            <Input
              id="emailId"
              name="emailId"
              value={formData.emailId}
              onChange={handleChange}
              placeholder="Enter your email address"
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel htmlFor="linkedInProfile">LinkedIn Profile</FormLabel>
            <Input
              id="linkedInProfile"
              name="linkedInProfile"
              value={formData.linkedInProfile}
              onChange={handleChange}
              placeholder="Enter your LinkedIn profile URL"
            />
          </FormControl>
          <FormControl isRequired >
            <FormLabel htmlFor="positionApplyingFor">Location Applying For</FormLabel>
            <Select
              id="positionApplyingFor"
              name="positionApplyingFor"
              value={formData.positionApplyingFor}
              onChange={handleChange}
              placeholder="Select Location"
            >
              <option value="Bangalore">Bangalore</option>
              <option value="Singapore">Singapore</option>
              <option value="Dubai">Dubai</option>
              <option value="Germany">Germany</option>
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="totalYearsOfExperience">
              Total Years of Experience (TYOE)
            </FormLabel>
            <Input
              id="totalYearsOfExperience"
              name="totalYearsOfExperience"
              value={formData.totalYearsOfExperience}
              onChange={handleChange}
              placeholder="Enter your total years of experience"
            />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="relevantYearsOfExperience">
              Relevant Years of Experience (RYOE)
            </FormLabel>
            <Input
              id="relevantYearsOfExperience"
              name="relevantYearsOfExperience"
              value={formData.relevantYearsOfExperience}
              onChange={handleChange}
              placeholder="Enter your relevant years of experience"
            />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="noticePeriod">Notice Period</FormLabel>
            <Input
              id="noticePeriod"
              name="noticePeriod"
              value={formData.noticePeriod}
              onChange={handleChange}
              placeholder="Enter your notice period"
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel htmlFor="resume">Resume</FormLabel>
            <Input
              id="resume"
              name="resume"
              type="file"
              accept=".pdf,.doc,.docx"
              onChange={handleChange}
            />
          </FormControl>
          <Button mt={4} colorScheme="blue" type="submit">
            Submit
          </Button>
        </form>
      )}
    </Box>
  );
}

export default Apply;
