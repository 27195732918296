import React from 'react'
import { Box, Button,Text, FormControl, FormLabel, Input, Textarea, VStack } from '@chakra-ui/react'
import { useState,useEffect } from 'react'
import axios from "axios"

const Showjobs = () => {

    const [jobdata,setJobdata] = useState([])
    const getdata=async()=>{
        try{
          let data=await axios.get("https://bert-labs-website.onrender.com/admin/job")
          console.log(data.data.response)
          setJobdata(data.data.response)
        }
        catch(err){
          console.log(err)
        }
      }
    const handleDelete=async(id)=>{
      try {
        await axios.delete(`https://bert-labs-website.onrender.com/admin/job/delete/${id}`);
        // Update the state to remove the deleted resource
        setJobdata(jobdata.filter(elem=> elem._id !== id));
      } catch (err) {
        console.log(err);
      }
    }
    
    
      useEffect(()=>{
        getdata()
      },[])
  return (
    <Box width={"120%"} ml={"-25%"} mb={"10px"}> <Box padding={"25px"} fontSize={"34px"} >
        Jobs Posted
      </Box>
     
      <VStack spacing={4} align="stretch">
      <Box
        display="flex"
        justifyContent="space-between"
        padding="7px"
        backgroundColor="gray.200"
        fontWeight="bold"
      >
        <Text flexBasis="20%">Job Title</Text>
        <Text flexBasis="20%">Employment Type</Text>
        <Text flexBasis="20%">Location</Text>
        <Text flexBasis="20%">Experience</Text>
        <Text flexBasis="20%">Notice Period</Text>
      </Box>
      {jobdata.map((job, index) => (
        <Box
          key={index}
          display="flex"
          justifyContent="space-between"
          padding="7px"
          borderBottom="1px solid"
          borderColor="gray.300"
        >
          <Text flexBasis="20%">{job.title}</Text>
          <Text flexBasis="20%">{job.employementType}</Text>
          <Text flexBasis="20%">{job.location}</Text>
          <Text flexBasis="20%">{job.experience}</Text>
          <Text flexBasis="20%">{job.noticePeriod}</Text>
          <Button onClick={()=>handleDelete(job._id)}>Delete</Button>
        </Box>
      ))}
    </VStack>
    </Box>
  )
}

export default Showjobs