import React from "react";
import { Image, Box, Button, Text } from "@chakra-ui/react";
import {HashRouter} from "react-router-dom";
import {HashLink} from "react-router-hash-link";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import bertAksh from "../../assets/HomePage/BertMaximus/bert-aksh.svg";
import bertMilo from "../../assets/HomePage/BertMaximus/bert-milo.svg";
import bertBranch from "../../assets/HomePage/BertMaximus/bert-branch.svg";
import bertMinion from "../../assets/HomePage/BertMaximus/bert-minion.svg";
import bertMinnie from "../../assets/HomePage/BertMaximus/bert-minnie.svg";
import bertMinniecomm from "../../assets/HomePage/BertMaximus/bert-minniecomm.svg";
import bertTitan from "../../assets/HomePage/BertMaximus/bert-titan.svg";
import bertQrious from "../../assets/HomePage/Qrious.svg";
import predict from "../../assets/HomePage/How-we-do/Blending Predict.gif";
import optimize from "../../assets/HomePage/optimize.gif";
import bertAgreegator from "../../assets/HomePage/bert-microservices/bert-aggregator.svg";
import bertBridge from "../../assets/HomePage/bert-microservices/bert-bridge.svg";
import bertConvergence from "../../assets/HomePage/bert-microservices/bert-convergence.svg";
import bertDirective from "../../assets/HomePage/bert-microservices/bert-directive.svg";
import bertEnvironment from "../../assets/HomePage/bert-microservices/bert-environment.svg";
import bertInterface from "../../assets/HomePage/bert-microservices/bert-interface.svg";
import bertReceptor from "../../assets/HomePage/bert-microservices/bert-receptor.svg";
import bertRegistry from "../../assets/HomePage/bert-microservices/bert-registry.svg";
import bertReinforcement from "../../assets/HomePage/bert-microservices/bert-reinforcement.svg";
import bertRely from "../../assets/HomePage/bert-microservices/bert-relay.svg";
import bertRemote from "../../assets/HomePage/bert-microservices/bert-remote.svg";
import bertWarehouse from "../../assets/HomePage/bert-microservices/bert-warehouse.svg";
import bertWatch from "../../assets/HomePage/bert-microservices/bert-watch.svg";
import pole from "../../assets/HomePage/side-pole.svg";

const bps = [
  {
    title: "SENSE",
    id:"bert-maximus",
    description:
      "Our IoT devices sense/capture/ process accurately and with high granularity, the parameters related to the machine's health & operations, environmental conditions, and material information. They also actuate all I/O points and field instruments based on real-time control commands",
    headerImages: {
      head: "Bert Maximus®",
      desc: "IoT Devices",
      img: [
        bertAksh,
        bertMilo,
        bertBranch,
        bertMinion,
        bertMinnie,
        bertMinniecomm,
        bertTitan,
      ],
    },
  },
  {
    title: "CONNECT",
    id:"bert-qrious",
    description:
      "Several variable parameters sensed/captured/processed from our IoT devices and third-party IoT devices are real-time transmitted through IoT powered Wireless Sensor Network, where data gets aggregated/processed/analyzed/computed at the base station/ edge computing device",
    headerImages: {
      head: "Bert Qrious®",
      desc: "IoT powered Wireless Sensor Network",
      img: [bertQrious],
    },
  },
  {
    title: "INSIGHT",
    id:"bert-nova",
    description:
      "Data gets stored, processed, visually analyzed, AI computed, through a suite of microservices",
    headerImages: {
      head: "Bert Nova™",
      desc: "Big Data Lake & Distributed Computing",
      img: [
        bertAgreegator,
        bertBridge,
        bertConvergence,
        bertDirective,
        bertEnvironment,
        bertInterface,
        bertReceptor,
        bertRegistry,
        bertReinforcement,
        bertRely,
        bertRemote,
        bertWarehouse,
        bertWatch,
      ],
    },
  },
  {
    title: "PREDICT",
    id:"bert-geminus",
    description:
      "Deep Neural Network - First Principles powered operational Digital Twins of manufacturing process lines/process equipment/utility equipment provide the current state and the predicted state at an accuracy of 99.99%",
    headerImages: {
      head: "Bert Geminus™",
      desc: "Operational Digital twin",
      img: [predict],
    },
  },
  {
    title: "ACT",
    id:"bert-optimus",
    description:
      "Reinforcement Learning Agents of manufacturing process lines/process equipment/utility equipment bring energy efficiency improvement by 30%, production efficiency improvement by 3%",
    headerImages: {
      head: "Bert Optimus®",
      desc: "Reinforcement Learning Agent ",
      img: [optimize],
    },
  },
];

const BPSCard = () => {
  const arr = bps;

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
  };

  return (
    <Box fontFamily="Mulish">
      <Box margin={"2% 0"}>
        {arr.map((elem, index) => {
          return (
            <Box key={index}>
              <Box
                display={{
                  lg: "flex",
                  md: "inline-block",
                  base: "inline-block",
                }}
                backgroundColor="white"
                padding={{ lg: "3%", md: "0%", base: "0%" }}
                //margin={"10px 0px"}
                width={"100%"}
                // height={{lg:'315px',md:'250px',base:'300px'}}
                // border={"2px solid red"}
              >
                <Box
                  marginRight={"5%"}
                  display={{ lg: "initial", md: "none", base: "none" }}
                  height={"auto"}
                  fontFamily="Mulish"
                >
                  <Image src={pole}></Image>
                </Box>
                <Box
                  width={{ lg: "60%", md: "100%", base: "100%" }}
                  paddingRight={{ lg: "10%" }}
                  fontFamily="Mulish"
                >
                  <Text
                    fontSize={{ lg: "23px", md: "25px", base: "18px" }}
                    fontFamily="Mulish"
                    fontWeight={"800"}
                    margin={"30px 0px"}
                    className="heading5"
                    color="#000"
                    fontStyle="normal"
                    lineHeight="32px"
                    // border={"2px solid red"}
                    padding={{ base: "0px 0px 0px 7%" }}
                  >
                    {elem.title}
                  </Text>
                  <Text
                    fontSize={{ lg: "20px", md: "25px", base: "18px" }}
                    fontFamily="Mulish"
                    fontWeight={"400"}
                    marginBottom="8px"
                    className="sub-heading5"
                    color="#000"
                    fontStyle="normal"
                    lineHeight="160%"
                    padding={{ base: "0px 4% 0px 7%" }}
                  >
                    {elem.description}
                  </Text>
                </Box>
                <Box
                  maxW={{ lg: "30%", md: "100%", base: "100%" }}
                  mx="auto"
                  padding={{ lg: "0", md: "5% 5%", base: "5% 5%" }}
                  backgroundColor={{
                    lg: "#ffffff",
                    md: "#FBF8F8",
                    base: "#FBF8F8",
                  }}
                >
                  <Text
                    margin={"5px 0px"}
                    color="#000"
                    fontFamily="Mulish"
                    fontSize="20px"
                    fontStyle="normal"
                    fontWeight="400"
                    lineHeight="28px"
                  >
                    {elem.headerImages.head}
                {/* <span style={{ verticalAlign: "top", fontSize: "0.5em" }}>TM</span> */}
                  </Text>
                  <Text
                    margin={"5px 5px 10px 5px"}
                    color="#000"
                    fontFamily="Mulish"
                    fontSize="24px"
                    fontStyle="normal"
                    fontWeight="500"
                    lineHeight="40px"
                  >
                    {elem.headerImages.desc}
                  </Text>
                  {elem.headerImages.img.length > 1 ? (
                    <Slider {...settings}>
                      {elem.headerImages.img.map((value, subIndex) => {
                        return (
                          <Box key={index} marginTop={"10px"}>
                            <Image
                              src={value}
                              alt={`bert-image-${subIndex}`}
                              key={subIndex}
                              fontFamily="Mulish"
                              color={"#707070"}
                              width="100%"
                              height="auto"
                              objectFit="cover"
                            />
                          </Box>
                        );
                      })}
                    </Slider>
                  ) : (
                    elem.headerImages.img.map((value, subIndex) => {
                      return (
                        <Box key={index}>
                          <Image
                            src={value}
                            alt={`bert-image-${subIndex}`}
                            key={subIndex}
                            fontFamily="Mulish"
                            color={"#707070"}
                            width="100%"
                            height="auto"
                            objectFit="cover"
                          />
                        </Box>
                      );
                    })
                  )}
                </Box>
              </Box>
              <Box
                position={{ lg: "relative" }}
                padding={{ lg: "0 3%", md: "0% 7% 4% 7%", base: "0% 7% 4% 7%" }}
                backgroundColor={{
                  lg: "#ffffff",
                  md: "#FBF8F8",
                  base: "#FBF8F8",
                }}
                display={{ lg: "block", md: "flex", base: "flex" }}
                justifyContent={{ md: "center", base: "center" }}
                mt={{ base: "0px" }}
              >
                <HashLink smooth to={`/products/#${elem.id}`}><Button
                  color="#d63e3e"
                  backgroundColor="#ffffff"
                  border={"1px solid #d63e3e"}
                  position={{ lg: "absolute" }}
                  bottom={{ lg: "40px" }}
                  marginLeft={{ lg: "11%" }}
                  width={{ lg: "250px", md: "90%", base: "90%" }}
                  mt={{ base: "0px" }}
                >
                  Explore Products
                </Button></HashLink>
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default BPSCard;
