import React from "react";
import { Box, Text, Button, Image } from "@chakra-ui/react";
import Backimage from "../assets/About/about-bg.png";
import Profileimage from "../assets/About/about-pic.png";
import Bertteamsmall from "../assets/About/Frame 3350.png";
import teampic from "../assets/About/Group 4270.png";
import target from "../assets/Icons/Group 4194.png";
import iconpic from "../assets/Icons/Group 3324.png";
import { useState, useEffect } from "react";
import AwardsCard from "../Components/Card/AwardsCard";
import ContactCard from "../Components/Card/ContactCard";
import "./About.css";
import newsimage from "../assets/Resources/news-all.png";
import bertGroup from "../assets/About/bertGroup.png";
import { useNavigate } from "react-router-dom";
import { HashLink} from "react-router-hash-link"

function About() {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);
  const data = [
    {
      text: "Customer First",
      color: "#FF6347",
      description:
        "Business Transformation\nKnowing their customers better\nSolving Complex Problems\nAddressing Pain Points",
    },
    {
      text: "Integrity",
      color: "#4682B4",
      description:
        "Commitment & perseverance\nFairness & Transparency\nStrong Work Ethics\nCompany First",
    }, // SteelBlue
    {
      text: "Frugality",
      color: "#32CD32",
      description:
        "Relentless Focus on Cost Rationalisation\nSustainable Profitable Growth\nResponsible Use of Corporate Resources\nEliminate Waste",
    }, // LimeGreen
    {
      text: "Sustained Growth",
      color: "#FFD700",
      description:
        "High Performance\nExponential & Profitable Growth\nBuilt to Last\nEthics & Corporate Governance",
    }, // Gold
    {
      text: "Innovation",
      color: "#7B68EE",
      description:
        "Changing the World\nTransformational Impact\nFuelling Ingenuity\nEnhancing Capability",
    }, // MediumSlateBlue
    {
      text: "Gratitude",
      color: "#6A5ACD",
      description:
        "Thanks & Sorry is powerful\nHumility & Patience\nKindness & Generosity\nCompassion\nEarthness\nDown-to-Earth",
    }, // SlateBlue
  ];

  const [hoveredindex, setHoveredindex] = useState(null);

  return (
    <Box fontFamily={"Mulish"} paddingX={{ base: "7px", lg: "64px" }}>
      <Box
        className="about-bg"
        backgroundImage={Backimage}
        backgroundSize={{ lg: "cover", md: "cover", base: "cover" }}
        objectFit={"cover"}
        backgroundPosition="center"
        // minHeight={{ lg: "100vh", md: "35vh", base: "40vh" }} // Adjust the height as needed
        // maxHeight={{ lg: "100vh", md: "40vh", base: "40vh" }}
        marginX={{ base: "-7px", lg: "-64px" }}
        display={{ lg: "flex" }}
        position={{ base: "relative" }}
        padding={{ base: "7px", lg: "16px" }}
        // border={"10px solid red"}
      >
        {/* Your content goes here */}
        <Box width={{ lg: "55%", sm: "60%" }} marginLeft={{ lg: "25px" }}>
          <Box margin={0} padding={"4"}>
            <Text
              fontSize={{ lg: "70px", base: "27px" }}
              margin={0}
              marginTop={"-17px"}
              fontWeight={"bold"}
              lineHeight={{ lg: "77px", base: "43px" }}
            >
              Welcome to Bert Labs<span style={{ verticalAlign: "top", fontSize: "0.5em" }}>TM</span> where your Space Shuttle Ride Begins!
            </Text>
          </Box>

          <Box
            width={{ base: "50%", lg: "70%" }}
            mt={{ lg: "25px", sm: "10px" }}
            padding={{ lg: "7px", sm: "10px" }}
            ml={{ base: "25px", lg: "0px" }}
          >
            <Text fontSize={{ lg: "20px", mid: "10px" }}>
              We are solving complex problems through Bert Platform Solution<span style={{ verticalAlign: "top", fontSize: "0.5em" }}>TM</span>{" "}
              innovation
            </Text>
            <HashLink smooth to="/#get-in-touch">
              <Button
                display={{ base: "none", lg: "block" }}
                colorScheme="#d63e3e"
                mr="2"
                className="button"
                marginTop={"70px"}
              >
                Get in Touch
              </Button>
            </HashLink>{" "}
          </Box>
        </Box>
        <Box
          width={"45%"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          position={{ base: "absolute" }}
          right={{ lg: "10px", md: "10px", base: "5px" }}
          top={{ lg: "100px", md: "100px", base: "140px" }}
        >
          <Image
            src={Profileimage}
            height={{ base: "120px", lg: "370px", md: "200px" }}
          />
        </Box>
      </Box>

      <Box display={{ base: "none", lg: "block" }} mt={"24px"}>
        <Box padding={"24px"}>
          <Box display={"flex"}>
            <Box width={"40%"} fontSize={"48px"} height={"100%"} mt={"200px"}>
              <Box
                typeof="span"
                fontSize={"24px"}
                color={"#D63E3E"}
                fontWeight={"700"}
              >
                Our mission
              </Box>
              <Box fontWeight={"600"}>Nation Building </Box>
              <Box fontSize={"36px"}>
                through creation of a Global Institution
              </Box>
            </Box>
            <Box>
              {/* <Box>
                <Image src={teampicone} />
              </Box> */}
              <Box mt={"16px"}>
                <Image src={Bertteamsmall} />
              </Box>
            </Box>
          </Box>
        </Box>

        <Box paddingY={"72px"} mt={"24px"}>
          <Text fontWeight={"bold"} fontSize={"36px"}>
            Core Values
          </Text>

          <Box
            display={"grid"}
            gridTemplateColumns={{ lg: "repeat(3,1fr)", md: "repeat(2,1fr)" }}
            gap={"16px"}
            padding={"20px"}
          >
            {data.map((elem, index) => {
              return (
                <Box
                  key={index}
                  borderLeft={`7px solid ${elem.color}`}
                  height={"300px"}
                  borderRadius={"25px"}
                  boxShadow="0px 4px 8px rgba(0, 0, 0, 0.1)"
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  mt={index === 1 || index === 4 ? "7px" : "35px"}
                  position="relative"
                  transition="margin-top 0.3s ease-in-out"
                  onMouseEnter={() => setHoveredindex(index)}
                  onMouseLeave={() => setHoveredindex(null)}
                  padding={"25px"}
                >
                  <Text
                    fontSize={hoveredindex === index ? "18px" : "30px"}
                    fontWeight={"bold"}
                    color={hoveredindex === index ? "#d63e3e" : "black"}
                  >
                    {hoveredindex === index
                      ? elem.description.split("\n").map((line, i) => (
                          <Box mt={"7px"} key={i}>
                            {"• " + line}
                          </Box>
                        ))
                      : elem.text}
                  </Text>
                </Box>
              );
            })}
          </Box>
        </Box>
        <Box
          background={"#d3d3d3"}
          mt={{ lg: "64px", base: "70px" }}
          p={{
            lg: "30px 75px 30px 75px",
            md: "15px 15px 15px 15px",
            base: "10px 4px 10px 4px",
          }}
        >
          <Text
            align="center"
            fontSize={{ lg: "48px", base: "24px" }}
            color={"gray"}
            fontWeight={"bold"}
          >
            Who’s talking about us
          </Text>
          <Box objectFit={"cover"}>
            <Image
              src={newsimage}
              width={"100%"}
              height={{ lg: "auto", md: "auto", base: "auto" }}
              mt={"20px"}
            />
          </Box>
        </Box>
        <Box>
          <Box>
            <Box
              width={"100%"}
              fontSize={"48px"}
              height={"100%"}
              textAlign={"center"}
            >
              <Box fontWeight={"600"}>It's all about People </Box>
              <Box
                typeof="span"
                fontSize={"24px"}
                color={"#D63E3E"}
                fontWeight={"700"}
              >
                And their Innovation
              </Box>
              <Button
                fontSize={"24px"}
                bg={"white"}
                border={"2px solid black"}
                onClick={() => {
                  navigate("/teams");
                }}
              >
                Meet the Leadership Team
              </Button>
              <Box display={"flex"} justifyContent={"center"} mt={"27px"}>
                <Image src={bertGroup} />
              </Box>
            </Box>
          </Box>
        </Box>

        <Box>
          <Image src={target} />
        </Box>
        <Box
          bgGradient="linear(to-r, #010C1E, blue.500)"
          w="100%"
          p={4}
          borderRadius="md"
          display={"flex"}
          height={"387px"}
          mt={"34px"}
          width={"100%"}
        >
          <Box width={"60%"} padding={"70px"}>
            <Box color={"white"} fontWeight={"bold"} fontSize={"43px"}>
              Unlock our products full potential
            </Box>
            <HashLink smooth to="/#get-in-touch">
              <Button
                colorScheme="#d63e3e"
                mr="2"
                className="button"
                mt={"20px"}
              >
                Get in Touch
              </Button>
            </HashLink>{" "}
          </Box>
          <Box width={"40%"}>
            <Image src={iconpic} height={"100%"} />
          </Box>
        </Box>
        <Box>
          <AwardsCard />
        </Box>
      </Box>

      <Box display={{ base: "block", lg: "none" }} padding={"20px"}>
        <Box>
          <Text color="#d63e3e" fontWeight={"bold"}>
            Our mission
          </Text>

          <Text>
            of{" "}
            <Text as="span" fontWeight="bold">
              Nation Building
            </Text>{" "}
            through creation of a Global Institution
          </Text>
        </Box>
        <Box mt={"17px"}>
          <Image src={Bertteamsmall} width={"700px"} />
        </Box>

        <Text
          fontWeight={"bold"}
          marginTop={"25px"}
          textAlign={"center"}
          fontSize={"36px"}
        >
          Core Values
        </Text>

        <Box
          display={"grid"}
          gridTemplateColumns={{ md: "repeat(2,1fr)", base: "repeat(1,1fr)" }}
          gap={"15px"}
        >
          {data.map((elem, index) => {
            return (
              <Box
                key={index}
                borderLeft={`7px solid ${elem.color}`}
                height={"170px"}
                borderRadius={"25px"}
                boxShadow="0px 4px 8px rgba(0, 0, 0, 0.1)"
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                position="relative"
                transition="margin-top 0.3s ease-in-out"
                onMouseEnter={() => setHoveredindex(index)}
                onMouseLeave={() => setHoveredindex(null)}
                padding={"25px"}
              >
                <Text
                  fontSize={hoveredindex === index ? "10px" : "21px"}
                  fontWeight={"bold"}
                  color={hoveredindex === index ? "red" : "black"}
                >
                  {hoveredindex === index
                    ? elem.description.split("\n").map((line, i) => (
                        <Box mt={"7px"} key={i}>
                          {"• " + line}
                        </Box>
                      ))
                    : elem.text}
                </Text>
              </Box>
            );
          })}
        </Box>
        <Box
          background={"#d3d3d3"}
          mt={{ lg: "64px", base: "70px" }}
          p={{
            lg: "30px 75px 30px 75px",
            md: "15px 15px 15px 15px",
            base: "10px 4px 10px 4px",
          }}
        >
          <Text
            align="center"
            fontSize={{ lg: "48px", base: "24px" }}
            color={"gray"}
            fontWeight={"bold"}
          >
            Who’s talking about us
          </Text>
          <Box objectFit={"cover"}>
            <Image
              src={newsimage}
              height={{ lg: "70%", md: "auto", base: "auto" }}
              mt={"20px"}
            />
          </Box>
        </Box>
        <Box justifyContent={"center"} alignItems={"center"}>
          <Text
            display={"flex"}
            mt="20px"
            fontSize={"25px"}
            fontWeight={"bold"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            It's all about people
          </Text>
          {/* <Text justifyContent={"center"} alignItems={"center"}>
          Nation Building
          </Text> */}{" "}
          {/* of{' '} */}
          {/* <Text as="span" fontWeight="bold" color={"#d63e3e"} justifyContent={"center"} alignItems={"center"} marginLeft={"50px"}
          
          >
        
        </Text>{' '} */}
        </Box>

        <Box>
          <Image src={teampic} width={"700px"} mt={"20px"} />
        </Box>
        <Box
          bgGradient="linear(to-r, #010C1E, blue.500)"
          w="100%"
          pr={2}
          py={2}
          borderRadius="md"
          display={"flex"}
          height={"207px"}
          mt={"34px"}
        >
          <Box width={"60%"} padding={"34px"}>
            <Box color={"white"} fontWeight={"bold"} fontSize={"17px"}>
              Unlock our products full potential
            </Box>
            <HashLink smooth to="/#get-in-touch">
              <Button
                colorScheme="#d63e3e"
                mr="2"
                className="button"
                mt={"20px"}
              >
                Get in Touch
              </Button>
            </HashLink>{" "}
          </Box>
          <Box width={"40%"}>
            <Image src={iconpic} height={"100%"} />
          </Box>
        </Box>
        <Box>
          <AwardsCard />
        </Box>
      </Box>
      <Box paddingBottom={"25px"}>
        <ContactCard />
      </Box>
    </Box>
  );
}

export default About;
