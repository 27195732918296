import React from "react";
import { Box, Text, Image, Button, Link } from "@chakra-ui/react";
import partners from "../../assets/contact-details/partners.png";
import pressKit from "../../assets/contact-details/press-kit.png";
import sales from "../../assets/contact-details/sales.png";
import talent from "../../assets/contact-details/talent.svg";
import { useNavigate } from "react-router-dom";
import {useRef} from "react";
import './scrollToSection.css'

const contactData = [
  {
    title: "Sales",
    image: sales,
    desc: "For purchasing Bert Platform Solution™ and Products",
    to:"sales"
  },
  {
    title: "Channel Partners",
    image: partners,
    desc: "Interested in partnership? Let's team up.",
    to:"channelpartners"
  },
  {
    title: "Media",
    image: pressKit,
    desc: "Require media-related information or looking to connect",
    to:"media"
  },
  {
    title: "Talent",
    image: talent,
    desc: "Want to join our team? Explore opportunities.",
    to:"careers"

  },
];

const ContactCard = () => {
  const navigate=useNavigate()

  return (
    <Box display={"flex"} flexDirection={"column"}>
      <section id="get-in-touch">
      <Text
        align={{ lg: "left", md: "center", base: "center" }}
        fontFamily={"Mulish"}
        fontSize={{ lg: "36px", md: "36px", base: "22px" }}
        fontWeight={{ lg: "700", md: "700", base: "600" }}
        ml={{ sm: "10px", lg: "25px" }}
      >
        Let us know how can we help
      </Text></section>
      <Box
        display={{ lg: "flex", md: "grid", base: "inline-block" }}
        flexDirection="row"
        justifyContent="center"
        alignSelf={{ base: "center" }}
        gap="24px"
        marginTop="7px"
        alignItems="center"
        width={{ lg: "100%", md: "85%", base: "95%" }}
        padding={{ lg: "0px", md: "0%", base: "2%" }}
        gridTemplateColumns={{ md: "repeat(2, 1fr)", base: "auto" }}
      >
        {contactData.map((item, index) => (
          <Box
            key={index}
            className="card2"
            border={"2px solid rgba(242, 242, 242, 0.50)"}
            margin={"10px 0px"}
            borderRadius="8px"
            boxShadow="1px 3px 20px -1px rgba(33, 33, 33, 0.15)"
            width={{ lg: "20%", md: "100%", base: "100%" }}
            height={{ lg: "350px", md: "320px", base: "150px" }}
            position={"relative"}
            padding={{ lg: "1%", md: "4%", base: "2%" }}
            display={{ lg: "block", md: "block", base: "flex" }}
            justifyContent={"space-around"}
          >
            <Box
              display={{ lg: "flex", md: "flex", base: "block" }}
              justifyContent={"center"}
              marginTop={{ lg: "0", md: "0", base: "30px" }}
            >
              <Image
                src={item.image}
                alt={item.title}
                width={{ lg: "109px", md: "109px", base: "80px" }}
                height={{ lg: "129px", md: "129px", base: "auto" }}
                paddingTop={{ lg: "42px", md: "30px", base: "10px" }}
              />
            </Box>
            <Box className="container2">
              <Text
                fontSize={{ lg: "26px", md: "26px", base: "18px" }}
                fontWeight="700"
                lineHeight="31.47px"
                color="#333333"
                paddingTop="24px"
                textAlign="center"
              >
                {item.title}
              </Text>
              <Text
                fontSize={{ lg: "16px", md: "16px", base: "14px" }}
                fontWeight="400"
                lineHeight="19.36px"
                color="#707070"
                paddingTop={{ lg: "27px", md: "", base: "10px" }}
                paddingLeft={{ lg: "0", md: "0px", base: "15px" }}
                textAlign="center"
                // position={{md:"absolute"}}
                // top={{md:"220px"}}
                // left={{md:"0px"}}
              >
                {item.desc}
              </Text>

              <Link>
                <Text
                  position={"absolute"}
                  bottom={{ lg: "20px", md: "20px", base: "5px" }}
                  left={{ lg: "32%", md: "32%", base: "68%" }}
                  color={"#d63e3e"}
                  onClick={()=>{navigate(`/demo/${item.to}`)}}
                >
                  Contact us {">"}
                </Text>
              </Link>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default ContactCard; 
