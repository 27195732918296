import React, { useState, useEffect } from "react";
import { Box, Image, Button, Text, Divider, Link } from "@chakra-ui/react";
import axios from "axios";
import { HashLink } from "react-router-hash-link";

const Innovation = () => {
  const [resourcedata, setResourcedata] = useState([ {
    _id: '663360f27faf5870a21d0e3f',
    paragraph: 'Bert Platform Solution™ for optimizing Energy and o…y using Physics and Machine Learning based models',
    text: 'Automobile-Optimizing Energy Consumption of ED Oven',
    category: 'Bert Labs IPR',
    imageUrl: 'https://bertlabs-website.s3.ap-south-1.amazonaws.com/media/ipr/edovenautomobile.png',
    // Add other properties as needed
  },
  {
    _id: '663360f27faf5870a21d0e40',
    paragraph: 'Bert Platform Solution™ for optimizing Fuel and Ene…y using Physics and Machine Learning based models',
    text: 'Material Manufacturing - Fuel and Energy Optimization for a Kiln',
    category: 'Bert Labs IPR',
    imageUrl: 'https://bertlabs-website.s3.ap-south-1.amazonaws.com/media/ipr/materialmanufacturing.png',
    // Add other properties as needed
  },
  {
    _id: '663360f27faf5870a21d0e41',
    paragraph: 'Bert Platform Solution™ for optimizing energy effic…y using Physics and Machine Learning based models',
    text: 'Airport - Energy Management of HVAC Equipment',
    category: 'Bert Labs IPR',
    imageUrl: 'https://bertlabs-website.s3.ap-south-1.amazonaws.com/media/ipr/airport.jpg',
    // Add other properties as needed
  },
  {
    _id: '663360f27faf5870a21d0e42',
    paragraph: 'Bert Platform Solution™ for optimizing energy effic…y using Physics and Machine Learning based models',
    text: 'Hotel - Energy Management of HVAC Equipment',
    category: 'Bert Labs IPR',
    imageUrl: 'https://bertlabs-website.s3.ap-south-1.amazonaws.com/media/ipr/hotel.jpg',
    // Add other properties as needed
  },
  {
    _id: '663360f27faf5870a21d0e43',
    paragraph: 'Bert Platform Solution™ for optimizing energy effic…y using Physics and Machine Learning based models',
    text: 'Mall - Energy Management of HVAC Equipment',
    category: 'Bert Labs IPR',
    imageUrl: 'https://bertlabs-website.s3.ap-south-1.amazonaws.com/media/ipr/mall.jpg',
    // Add other properties as needed
  }]);

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("resourcedata"));
    if (storedData) {
      setResourcedata(storedData);
    } else {
      getResources();
    }
  }, []);

  const getResources = async () => {
    try {
      let data = await axios.get(`https://bert-labs-website.onrender.com/admin/resources`);
      setResourcedata(data.data);
      localStorage.setItem("resourcedata", JSON.stringify(data.data));
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Box fontFamily="Mulish">
      <Box margin={"auto"}>
        <Box padding={{ lg: "64px", base: "16px" }}>
          <Text
            alignText="center"
            fontSize={{ lg: "36px", base: "17px" }}
            color={"gray.600"}
            fontWeight={"bold"}
            // mt={{ lg: "64px", base: "34px" }}
          >
            Innovation at Bert Labs<span style={{ verticalAlign: "top", fontSize: "0.5em" }}>TM</span>
          </Text>

          <Box
            display={"grid"}
            gridTemplateColumns={{
              xl: "repeat(4,1fr)",
              lg: "repeat(3,1fr)",
              md: "repeat(2,1fr)",
              sm: "repeat(1,1fr)",
            }}
            mt={"25px"}
            gap={{ lg: "25px", base: "10px" }}
          >
            {resourcedata
              .filter((elem) => elem.category === "Bert Labs IPR")
              .map((elem, index) => (
                <Box
                  key={index}
                  borderRadius="10px"
                  boxShadow="rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px"
                  padding="10px"
                  position={"relative"}
                >
                  <Box width="100%" height={"270px"}>
                    <Image src={elem.imageUrl} width="100%" height={"100%"} />
                  </Box>
                  {elem.date ? (
                      <Box display="flex" justifyContent="end" mt={"20px"}>
                        {new Date(elem.date).toLocaleDateString("en-US", {
                          month: "long",
                          day: "numeric",
                          year: "numeric",
                        })}
                      </Box>
                    ) : null}
                  {elem.imagetwoUrl ? (
                    <Box mt={"5px"} maxWidth={"266px"} height={"auto"}>
                      <Image
                        src={elem.imagetwoUrl}
                        maxWidth={"266px"} height={"50px"}
                      />
                    </Box>
                  ) : null}

                  <Box height="50%" mt="5px" padding="10px">
                    <Divider />
                    <Text mt="15px" fontSize="17px" lineHeight="25px">
                      {elem.text}
                    </Text>
                    <Text
                      mt="10px"
                      fontSize="14px"
                      lineHeight="21px"
                      color={"gray"}
                    >
                      {elem.paragraph}
                    </Text>

                    <Box position={"absolute"} bottom={"3px"} right={"10px"}>{elem.link ? (
                      <Link display="flex" justifyContent="end" mt={"20px"} color={"#0d6efd"} target="_blank" href={elem.link}>
                        view more...
                      </Link>
                    ) : null}</Box>

                  </Box>
                </Box>
              ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Innovation;
