import React, { useEffect, useState } from "react";
import { Box, Text, Image } from "@chakra-ui/react";
import axios from "axios";
import {
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Button,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import Careers from "./Careers";

const Demo = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    companyName: "",
    phoneNumber: "",
    message: "",
  });

  useEffect(()=>{
    window.scrollTo(0, 0); 
  },[])
  const [error, setError] = useState("");
  const [submitted,setSubmitted] = useState(false)
  const params=useParams()
  console.log(params)
  


  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const reqbody={
        ...formData,
        category:params.id
      }
      console.log(reqbody)
      const response = await axios.post("https://bert-labs-website.onrender.com/contact", reqbody);
      console.log("Response from backend:", response.data);
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        companyName: "",
        phoneNumber: "",
        message: "",
        
      });
     setSubmitted(true)
    } catch (error) {
      console.error("Error submitting form:", error);
      setError("An error occurred while submitting the form. Please try again.");
    }
  };

  const bertData = [
    {
      title:"Sales",
      title2:"Sales",
      para:"Explore what makes Bert Platform Solution™ Unique",
      infos:[
       "Operational Efficiency","Data-Driven Insights","Scalability","Enhanced Security"
      ]   
    },
    {
      title:"demo",
      title2: "Request a demo",
      para: "We're excited to give you a personalized walkthrough.",
      infos: [
        "Explore what makes Bert Platform Solution™ unique",
        "Discuss pricing",
        "Explore our customized solutions",
        "Get a sneak peak at our roadmap"
      ]
    },
    {
      title: "ChannelPartners",
      title2:"Channel Partners",
      para: "We're excited to give you a personalized walkthrough.",
      infos: [
        "Explore what makes Bert Platform Solution™ unique",
        "Explore our customized solutions",
        "Increased Revenue Streams",
        "Collaborative Marketing and Sales Efforts"
      ]
    },
    {
      title: "Media",
      title2: "Media Inquires",
      para: "We're excited to give you a personalized walkthrough.",
      infos: [
        "Operational Efficiency and Quality Improvement",
        "Production Efficiency and Quality Improvement",
        "Capital Efficiency",
        "Sustainability"
      ]
    }

  ];
  const index = bertData.findIndex((elem) => elem.title.toLowerCase() === params.id.toLowerCase());

  console.log(index)

  useEffect(()=>{
    let timer
    if(submitted){
      timer=setTimeout(()=>{
        setSubmitted(false)
      },4000)
    }
    return ()=>clearTimeout(timer)
  },[submitted])

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

 if(params.id==="careers")
 return <Careers/>

  return (
    <Box padding={"0.7% 5%"} mb={"40px"} display="flex" flexDirection={{base:"column",lg:"row",md:"row"}} gap={{base:"70px",lg:"0px",md:"10px"}} justifyContent={"space-around"} alignItems={"center"} fontFamily={"Mulish"}>
      <Box fontSize={"18px"} fontWeight={"400"}>
        <Text fontSize={"48px"}  fontWeight={"700"}>{bertData[index].title2}</Text>
        <Text lineHeight={"24px"} p={"10px 0"}>{bertData[index].para}</Text>
        {
          bertData[index].infos.map((elem)=>{
            return (
              <Box display={"flex"} p={"10px 0"}>
          <Image src={"https://bertlabs-website.s3.ap-south-1.amazonaws.com/Forms/charm_circle-tick.svg"}/>
          <Text lineHeight={"24px"}>{elem}</Text>
        </Box>
          )
          })
        }
      </Box>
      <Box  p={10} borderRadius={"10px"} boxShadow={"rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}>


        <form onSubmit={handleSubmit}>
          <Box display={{lg:"flex",base:"block"}}>
            <FormControl isRequired padding={"10px 10px"}>
              <FormLabel>
                First Name
              </FormLabel>
              <Input
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                border="none"
                borderBottom={"1px solid"}
                borderRadius={"0px"}
              />
            </FormControl>
            <FormControl isRequired padding={"10px 10px"} >
              <FormLabel>
                Last Name
              </FormLabel>
              <Input
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                border="none"
                borderBottom={"1px solid"}
                borderRadius={"0px"}
              />
            </FormControl>
          </Box>
          <FormControl isRequired padding={"0 10px"}  mt={"10px"}>
            <FormLabel>
              Work Email
            </FormLabel>
            <Input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              border="none"
              borderBottom={"1px solid"}
              borderRadius={"0px"}
            />
          </FormControl>
          <FormControl isRequired padding={"0 10px"}  mt={"10px"}>
            <FormLabel>
              Company Name
            </FormLabel>
            <Input
              type="text"
              name="companyName"
              value={formData.companyName}
              onChange={handleChange}
              border="none"
              borderBottom={"1px solid"}
              borderRadius={"0px"}
            />
          </FormControl>
          <FormControl isRequired padding={"0 10px"}  mt={"10px"}>
            <FormLabel>
              Phone Number
            </FormLabel>
            <Input
              type="tel"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              border="none"
              borderBottom={"1px solid"}
              borderRadius={"0px"}
            />
          </FormControl>
          <FormControl padding={"0 10px"}  mt={"20px"}>
            <FormLabel>Tell us more about how we can help you</FormLabel>
            <Textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              border="none"
              borderBottom={"1px solid"}
              borderRadius={"0px"}
            />
          </FormControl>
          <Button type="submit" colorScheme="red"  mt={"17px"}>
            Submit
          </Button>
          {error && <Text color="red">{error}</Text>}
        </form>
        {submitted && (
          <Box
            backgroundColor="green.500"
            color="white"
            padding="20px"
            borderRadius="5px"
            marginTop="20px"
            textAlign="center"
            mt={"10px"}
            style={{
              animation: "slideIn 0.5s ease forwards",
            }}
          >
            Thanks for contacting us! We will get back to you soon.
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Demo;
